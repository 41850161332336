import { authentication_sso_method_options, authentication_type_options, simulation_program_status_options, training_program_status_options, provision_type_options, user_type_options, job_status_options, sex_options, companion_voice_options, companion_type_options, get_ordered_timezone_options } from "./options";





// **************************************
// Dates
// **************************************


export const get_date_string = (date) => {
  const date_formatter = Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeStyle: "long",
    dateStyle: "long"
  });
  const date_string = date_formatter.format(new Date(date))

  return date_string
}

export const get_date_string_short = (date) => {
  const date_formatter = Intl.DateTimeFormat("en-US", {
    dateStyle: "long"
  });
  const date_string = date_formatter.format(new Date(date))

  return date_string
}

export const get_time_left_in_trial = (user_created_at) => {

  const now_date = new Date();
  const user_created_date = new Date(user_created_at)

  // Calculate the end date by adding 14 days to the start date
  const trial_end_date = new Date(user_created_date);
  trial_end_date.setTime(user_created_date.getTime() + (1000 * 60 * 60 * 24 * 14)); // 14 days

  // Calculate the difference in time between the end date and the current date
  const time_difference = trial_end_date.getTime() - now_date.getTime()

  return time_difference
}

export const get_days_left_in_trial = (user_created_at) => {

  // Calculate the difference in time between the end date and the current date
  const time_difference = get_time_left_in_trial(user_created_at)

  // Convert the time difference to days
  const days_left = Math.ceil(time_difference / (1000 * 60 * 60 * 24));

  return days_left
}


// **************************************
// Account & user data
// **************************************

export const user_type_map = {
  'end_user': 'User',
  'admin': 'Admin',
  'owner': 'Account owner',
}




export const account_plan_map = {
  alpha: "Alpha",
  trial: "Trial",
  companions_on_the_go: "Per companion",
  custom: "Custom",
}




export const get_account_status_pretty = (status: string) => {
  switch(status) {
    case "inactive": {
      return "Inactive"
    }
    case "active": {
      return "Active"
    }
    default: {
      return "error"
    }
  }
}







export const filter_type_display_map = {
  includes_text: "includes",
  equals_text: "equals",
  equals_option: "equals",
  before_date: "is before",
  after_date: "is after",
  equals_number: "equals",
  lte_number: "less than or equal to",
  gte_number: "greater than or equal to",
  lt_number: "less than",
  gt_number: "greater than",
}





export const user_property_map = {
  // Profile properties
  email_address: "Email address", // user_doc.email_address
  phone_number: "Phone number", // user_doc.phone_number
  first_name: "First name", // user_doc.user_metadata.first_name
  last_name: "Last name", // user_doc.user_metadata.last_name
  birthday: "Birthday", // user_doc.user_metadata.birthday
  sex: "Sex", // user_doc.user_metadata.sex
  timezone: "Timezone", // user_doc.user_metadata.timezone
}




export const get_user_property_value = (user_doc, user_property, is_formatted) => {
  switch(user_property) {
    // Profile properties
    case "email_address": {
      return (user_doc.email_address && !user_doc.email_address.startsWith("UNSET")) ? user_doc.email_address : "(Unspecified)"
    }
    case "phone_number": {
      return is_formatted 
        ? (user_doc.phone_number && !user_doc.phone_number.startsWith("UNSET") ? format_phone_number(user_doc.phone_number) : "(Unspecified)") 
        : (user_doc.phone_number && !user_doc.phone_number.startsWith("UNSET") ? user_doc.phone_number : "(Unspecified)")
    }
    case "first_name": {
      return (user_doc.user_metadata.first_name && !user_doc.user_metadata.first_name.startsWith("UNSET")) ? user_doc.user_metadata.first_name : "(Unspecified)"
    }
    case "last_name": {
      return (user_doc.user_metadata.last_name && !user_doc.user_metadata.last_name.startsWith("UNSET")) ? user_doc.user_metadata.last_name : "(Unspecified)"
    }
    case "birthday": {
      return is_formatted 
        ? (user_doc.user_metadata.birthday && !user_doc.user_metadata.birthday.startsWith("UNSET")) ? format_birthday(user_doc.user_metadata.birthday) : "(Unspecified)"
        : (user_doc.user_metadata.birthday && !user_doc.user_metadata.birthday.startsWith("UNSET")) ? user_doc.user_metadata.birthday : "(Unspecified)"
    }
    case "sex": {
      return is_formatted 
        ? (user_doc.user_metadata.sex && !user_doc.user_metadata.sex.startsWith("UNSET")) ? sex_map[user_doc.user_metadata.sex]  : "(Unspecified)"
        : (user_doc.user_metadata.sex && !user_doc.user_metadata.sex.startsWith("UNSET")) ? user_doc.user_metadata.sex  : "(Unspecified)"
    }
    case "timezone": {
      return is_formatted 
        ? (user_doc.user_metadata.timezone && !user_doc.user_metadata.timezone.startsWith("UNSET")) ? get_timezone_pretty(user_doc.user_metadata.timezone)  : "(Unspecified)"
        : (user_doc.user_metadata.timezone && !user_doc.user_metadata.timezone.startsWith("UNSET")) ? user_doc.user_metadata.timezone  : "(Unspecified)"
    }
    case "authentication_type": {
      return is_formatted 
        ? authentication_type_map[user_doc.authentication_metadata.type] 
        : user_doc.authentication_metadata.type
    }
    case "authentication_sso_method": {
      return is_formatted 
        ? (user_doc.user_metadata.sex && !user_doc.user_metadata.sex.startsWith("UNSET")) ? authentication_sso_method_map[user_doc.authentication_metadata.sso_method] : "(Unspecified)"
        : (user_doc.user_metadata.sex && !user_doc.user_metadata.sex.startsWith("UNSET")) ? user_doc.authentication_metadata.sso_method : "(Unspecified)"
    }
    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_user_property_filters = (property) => {
  switch(property) { 
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "phone_number": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "first_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "last_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "birthday": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "sex": {
      return {
        equals_option: {
          options: sex_options,
          format: (value) => { return sex_map[value] }
        },
        includes_text: {}
      }
    }
    case "timezone": {
      return {
        equals_option: {
          options: get_ordered_timezone_options(),
          format: (value) => { return get_timezone_pretty(value) }
        },
        includes_text: {}
      }
    }
    default: {
      return {}
    }
  }
}




// **************************************
// Companion data
// **************************************


export const companion_plan_map = {
  alpha: "Alpha",
  trial: "Trial",
  basic: "Basic",
  custom: "Custom",
}


export const companion_property_map = {
  // Profile properties
  email_address: "Email address", // companion_doc.email_address
  phone_number: "Phone number", // companion_doc.phone_number
  first_name: "First name", // companion_doc.companion_metadata.first_name
  last_name: "Last name", // companion_doc.companion_metadata.last_name
  birthday: "Birthday", // companion_doc.companion_metadata.birthday
  sex: "Sex", // companion_doc.companion_metadata.sex
  voice: "Voice", // companion_doc.companion_metadata.voice
  type: "Type", // companion_doc.companion_metadata.type
  personality_prompt: "Personality prompt", // companion_doc.companion_metadata.personality_prompt
  additional_prompt: "Additional prompt", // companion_doc.companion_metadata.additional_prompt
  primary_assigned_member_first_name: "Assigned member first name", // companion_doc.companion_metadata.assigned_members[0].member_metadata.first_name
  primary_assigned_member_last_name: "Assigned member last name", // companion_doc.companion_metadata.assigned_members[0].member_metadata.last_name
}



export const get_companion_property_value = (companion_doc, companion_property, is_formatted) => {
  switch(companion_property) {
    // Profile properties 
    case "email_address": {
      return (companion_doc.email_address && !companion_doc.email_address.startsWith("UNSET")) ? companion_doc.email_address : "(Unspecified)"
    }
    case "phone_number": {
      return is_formatted 
        ? (companion_doc.phone_number && !companion_doc.phone_number.startsWith("UNSET") ? format_phone_number(companion_doc.phone_number) : "(Unspecified)") 
        : (companion_doc.phone_number && !companion_doc.phone_number.startsWith("UNSET") ? companion_doc.phone_number : "(Unspecified)")
    }
    case "plan": {
      return is_formatted 
        ? (companion_doc.plan && !companion_doc.plan.startsWith("UNSET") ? companion_plan_map[companion_doc.plan] : "(Unspecified)") 
        : (companion_doc.plan && !companion_doc.plan.startsWith("UNSET") ? companion_doc.plan : "(Unspecified)")
    }
    case "first_name": {
      return (companion_doc.companion_metadata.first_name && !companion_doc.companion_metadata.first_name.startsWith("UNSET")) ? companion_doc.companion_metadata.first_name : "(Unspecified)"
    }
    case "last_name": {
      return (companion_doc.companion_metadata.last_name && !companion_doc.companion_metadata.last_name.startsWith("UNSET")) ? companion_doc.companion_metadata.last_name : "(Unspecified)"
    }
    case "birthday": {
      return is_formatted 
        ? (companion_doc.companion_metadata.birthday && !companion_doc.companion_metadata.birthday.startsWith("UNSET")) ? format_birthday(companion_doc.companion_metadata.birthday) : "(Unspecified)"
        : (companion_doc.companion_metadata.birthday && !companion_doc.companion_metadata.birthday.startsWith("UNSET")) ? companion_doc.companion_metadata.birthday : "(Unspecified)"
    }
    case "sex": {
      return is_formatted 
        ? (companion_doc.companion_metadata.sex && !companion_doc.companion_metadata.sex.startsWith("UNSET")) ? sex_map[companion_doc.companion_metadata.sex]  : "(Unspecified)"
        : (companion_doc.companion_metadata.sex && !companion_doc.companion_metadata.sex.startsWith("UNSET")) ? companion_doc.companion_metadata.sex  : "(Unspecified)"
    }
    case "voice": {
      return is_formatted 
        ? (companion_doc.companion_metadata.voice && !companion_doc.companion_metadata.voice.startsWith("UNSET")) ? companion_voice_map[companion_doc.companion_metadata.voice] : "(Unspecified)"
        : (companion_doc.companion_metadata.voice && !companion_doc.companion_metadata.voice.startsWith("UNSET")) ? companion_doc.companion_metadata.voice : "(Unspecified)"
    }
    case "type": {
      return is_formatted 
      ? (companion_doc.companion_metadata.type && !companion_doc.companion_metadata.type.startsWith("UNSET")) ? companion_type_map[companion_doc.companion_metadata.type] : "(Unspecified)"
      : (companion_doc.companion_metadata.type && !companion_doc.companion_metadata.type.startsWith("UNSET")) ? companion_doc.companion_metadata.type : "(Unspecified)"
    }
    case "personality_prompt": {
      return (companion_doc.companion_metadata.personality_prompt && !companion_doc.companion_metadata.personality_prompt.startsWith("UNSET")) ? companion_doc.companion_metadata.personality_prompt : "(Unspecified)"
    }
    case "additional_prompt": {
      return (companion_doc.companion_metadata.additional_prompt && !companion_doc.companion_metadata.additional_prompt.startsWith("UNSET")) ? companion_doc.companion_metadata.additional_prompt : "(Unspecified)"
    }
    case "primary_assigned_member_first_name": {
      return (companion_doc.companion_metadata.assigned_members[0].member_metadata.first_name && !companion_doc.companion_metadata.assigned_members[0].member_metadata.first_name.startsWith("UNSET")) ? companion_doc.companion_metadata.assigned_members[0].member_metadata.first_name : "(Unspecified)"
    }
    case "primary_assigned_member_last_name": {
      return (companion_doc.companion_metadata.assigned_members[0].member_metadata.last_name && !companion_doc.companion_metadata.assigned_members[0].member_metadata.last_name.startsWith("UNSET")) ? companion_doc.companion_metadata.assigned_members[0].member_metadata.last_name : "(Unspecified)"
    }
    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_companion_property_filters = (property) => {
  switch(property) {
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "phone_number": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "first_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "last_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "birthday": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "sex": {
      return {
        equals_option: {
          options: sex_options,
          format: (value) => { return sex_map[value] }
        },
        includes_text: {}
      }
    }
    case "voice": {
      return {
        equals_option: {
          options: companion_voice_options,
          format: (value) => { return companion_voice_map[value] }
        },
        includes_text: {}
      }
    }
    case "type": {
      return {
        equals_option: {
          options: companion_type_options,
          format: (value) => { return companion_type_map[value] }
        },
        includes_text: {}
      }
    }
    case "personality_prompt": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "additional_prompt": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    default: {
      return {}
    }
  }
}


export const companion_type_map = {
  friend: "Friend", 
}

export const companion_voice_map = {
  male: "Male",
  female: "Female" 
}



// **************************************
// Member data
// **************************************



export const member_property_map = {
  // Profile properties
  email_address: "Email address", // member_doc.email_address
  phone_number: "Phone number", // member_doc.phone_number
  first_name: "First name", // member_doc.member_metadata.first_name
  last_name: "Last name", // member_doc.member_metadata.last_name
  birthday: "Birthday", // member_doc.member_metadata.birthday
  sex: "Sex", // member_doc.member_metadata.sex
  timezone: "Timezone", // member_doc.member_metadata.timezone
  favorite_topics: "Favorite topics", // member_doc.member_metadata.favorite_topics
  avoid_topics: "Topics avoid", // member_doc.member_metadata.position
  history_prompt: "History prompt", // member_doc.member_metadata.history_prompt
  additional_prompt: "Additional prompt", // member_doc.member_metadata.additional_prompt
  primary_guardian_user_first_name: "Guardian first name", // member_doc.member_metadata.guardian_users[0].user_metadata.first_name
  primary_guardian_user_last_name: "Guardian last name", // member_doc.member_metadata.guardian_users[0].user_metadata.last_name
  primary_guardian_user_relationship: "Guardian relationship", // member_doc.member_metadata.guardian_users[0].relationship
}



export const get_member_property_value = (member_doc, member_property, is_formatted) => {
  switch(member_property) {
    // Profile properties
    case "email_address": {
      return (member_doc.email_address && !member_doc.email_address.startsWith("UNSET")) ? member_doc.email_address : "(Unspecified)"
    }
    case "phone_number": {
      return is_formatted 
        ? (member_doc.phone_number && !member_doc.phone_number.startsWith("UNSET") ? format_phone_number(member_doc.phone_number) : "(Unspecified)") 
        : (member_doc.phone_number && !member_doc.phone_number.startsWith("UNSET") ? member_doc.phone_number : "(Unspecified)")
    }
    case "first_name": {
      return (member_doc.member_metadata.first_name && !member_doc.member_metadata.first_name.startsWith("UNSET")) ? member_doc.member_metadata.first_name : "(Unspecified)"
    }
    case "last_name": {
      return (member_doc.member_metadata.last_name && !member_doc.member_metadata.last_name.startsWith("UNSET")) ? member_doc.member_metadata.last_name : "(Unspecified)"
    }
    case "birthday": {
      return is_formatted 
        ? (member_doc.member_metadata.birthday && !member_doc.member_metadata.birthday.startsWith("UNSET")) ? format_birthday(member_doc.member_metadata.birthday) : "(Unspecified)"
        : (member_doc.member_metadata.birthday && !member_doc.member_metadata.birthday.startsWith("UNSET")) ? member_doc.member_metadata.birthday : "(Unspecified)"
    }
    case "sex": {
      return is_formatted 
        ? (member_doc.member_metadata.sex && !member_doc.member_metadata.sex.startsWith("UNSET")) ? sex_map[member_doc.member_metadata.sex]  : "(Unspecified)"
        : (member_doc.member_metadata.sex && !member_doc.member_metadata.sex.startsWith("UNSET")) ? member_doc.member_metadata.sex  : "(Unspecified)"
    }
    case "timezone": {
      return is_formatted 
        ? (member_doc.member_metadata.timezone && !member_doc.member_metadata.timezone.startsWith("UNSET")) ? get_timezone_pretty(member_doc.member_metadata.timezone)  : "(Unspecified)"
        : (member_doc.member_metadata.timezone && !member_doc.member_metadata.timezone.startsWith("UNSET")) ? member_doc.member_metadata.timezone  : "(Unspecified)"
    }
    case "favorite_topics": {
      return (member_doc.member_metadata.favorite_topics && !member_doc.member_metadata.favorite_topics.startsWith("UNSET")) ? member_doc.member_metadata.favorite_topics : "(Unspecified)"
    }
    case "avoid_topics": {
      return (member_doc.member_metadata.avoid_topics && !member_doc.member_metadata.avoid_topics.startsWith("UNSET")) ? member_doc.member_metadata.avoid_topics : "(Unspecified)"
    }
    case "alert_topics": {
      return (member_doc.member_metadata.alert_topics && !member_doc.member_metadata.alert_topics.startsWith("UNSET")) ? member_doc.member_metadata.alert_topics : "(Unspecified)"
    }
    case "history_prompt": {
      return (member_doc.member_metadata.history_prompt && !member_doc.member_metadata.history_prompt.startsWith("UNSET")) ? member_doc.member_metadata.history_prompt : "(Unspecified)"
    }
    case "additional_prompt": {
      return (member_doc.member_metadata.additional_prompt && !member_doc.member_metadata.additional_prompt.startsWith("UNSET")) ? member_doc.member_metadata.additional_prompt : "(Unspecified)"
    }
    case "primary_guardian_user_first_name": {
      return (member_doc.member_metadata.guardian_users[0].user_metadata.first_name && !member_doc.member_metadata.guardian_users[0].user_metadata.first_name.startsWith("UNSET")) ? member_doc.member_metadata.guardian_users[0].user_metadata.first_name  : "(Unspecified)"
    }
    case "primary_guardian_user_last_name": {
      return (member_doc.member_metadata.guardian_users[0].user_metadata.last_name && !member_doc.member_metadata.guardian_users[0].user_metadata.last_name.startsWith("UNSET")) ? member_doc.member_metadata.guardian_users[0].user_metadata.last_name  : "(Unspecified)"
    }
    case "primary_guardian_user_relationship": {
      return (member_doc.member_metadata.guardian_users[0].relationship && !member_doc.member_metadata.guardian_users[0].relationship.startsWith("UNSET")) ? member_doc.member_metadata.guardian_users[0].relationship  : "(Unspecified)"
    }
    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_member_property_filters = (property) => {
  switch(property) {
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "phone_number": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "first_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "last_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "birthday": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "sex": {
      return {
        equals_option: {
          options: sex_options,
          format: (value) => { return sex_map[value] }
        },
        includes_text: {}
      }
    }
    case "timezone": {
      return {
        equals_option: {
          options: get_ordered_timezone_options(),
          format: (value) => { return get_timezone_pretty(value) }
        },
        includes_text: {}
      }
    }
    case "favorite_topics": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "avoid_topics": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "history_prompt": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "additional_prompt": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    default: {
      return {}
    }
  }
}




// **************************************
// Miscellaneous
// **************************************

export const sex_map = {
  male: "Male", 
  female: "Female",
}






export const get_timezone_pretty = (timezone) => {
  switch(timezone) {
    // case "America/New_York": {
    //   return "America/New_York (US/Eastern)"
    // }
    // case "America/Chicago": {
    //   return "America/Chicago (US/Central)"
    // }
    // case "America/Denver": {
    //   return "America/Denver (US/Mountain)"
    // }
    // case "America/Los_Angeles": {
    //   return "America/Los_Angeles (US/Pacific)"
    // }
    default: {
      return timezone
    }
  }
}




export const query_by_map = {
  area_code: "Area code", 
  city: "City",
  state: "State", 
  postal_code: "Zip code",
}





// **************************************
// Authentication types
// **************************************

export const authentication_type_map = {
  'disabled': 'Disabled',
  'password': 'Password',
  'sso': 'Single Sign-On',
}

export const authentication_sso_method_map = {
  'google_oauth': 'Google OAuth',
}


// **************************************
// Provision types
// **************************************

export const provision_type_map = {
  'disabled': 'Disabled',
  'native': 'Dashboard',
  'scim': 'SCIM',
}





export const submit_website_field_map = {
  email_address: "Email address",
  password: "Password",
  first_name: "First name",
  last_name: "Last name",
  credit_card_number: "Credit card number",
  credit_card_month: "Credit card month",
  credit_card_year: "Credit card year",
  credit_card_cvv: "Credit card CVV",
  credit_card_zip_code: "Credit card zip code",
}








// **************************************
// Stripe
// **************************************

export const get_invoice_status = (amount, status) => {
  if (amount === 0) {
    switch (status) {
      case "paid": {
        return "Settled"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
  // Payment amount is positive (debit)
  else if (amount > 0) {
    switch (status) {
      case "paid": {
        return "Paid"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
  // Payment amount is negative (credit)
  else if (amount < 0) {
    switch (status) {
      case "paid": {
        return "Credited"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
}


export const beautify_usd_amount = ( usd_amount ) => {

  let res = '';

  // Zero
  if (usd_amount === 0) {
    res = '$0'
  } 

  // Positive
  else if (usd_amount > 0) {

    const usd_amount__string = usd_amount.toString();

    if (usd_amount__string.length === 1) {
      res = `$0.0${usd_amount__string}`;
    }
    else {
      if (!(usd_amount__string.slice(0, usd_amount__string.length-2))) {
        res = `$0.${usd_amount__string.slice(usd_amount__string.length-2)}`;
      } else {
        res = `$${usd_amount__string.slice(0, usd_amount__string.length-2)}.${usd_amount__string.slice(usd_amount__string.length-2)}`;
      }
    }
  }

  // Negative
  else if (usd_amount < 0) {

    const abs_usd_amount = Math.abs(usd_amount)
    const abs_usd_amount__string = abs_usd_amount.toString();
    
    if (abs_usd_amount__string.length === 1) {
      res = `-$0.0${abs_usd_amount__string}`;
    }
    else {
      if (!(abs_usd_amount__string.slice(0, abs_usd_amount__string.length-2))) {
        res = `-$0.${abs_usd_amount__string.slice(abs_usd_amount__string.length-2)}`;
      } else {
        res = `-$${abs_usd_amount__string.slice(0, abs_usd_amount__string.length-2)}.${abs_usd_amount__string.slice(abs_usd_amount__string.length-2)}`;
      }
    }
  }


  return res;
}




export const format_phone_number = (phone_number) => {
  // Remove the +1 prefix for now
  const digits = phone_number.replace(/^\+1/, '');

  // Format as +1 (XXX) XXX-XXXX
  return `+1 (${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};


export const format_time = (time_string) => {
  const hours = parseInt(time_string.slice(0, 2), 10);
  const minutes = parseInt(time_string.slice(2), 10);
  const date = new Date(0, 0, 0, hours, minutes);
  return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
}


export const month_names = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];


export const format_birthday = (birthday) => {

  // Check if the input is a valid 8-character string in the format YYYYMMDD
  if (!/^\d{8}$/.test(birthday)) {
    return "(Error)";
  }

  const year = birthday.slice(0, 4);
  const month = birthday.slice(4, 6);
  const day = birthday.slice(6, 8);

  // Parse month and day to integers
  const month_index = parseInt(month, 10) - 1; // JavaScript months are 0-indexed
  const day_int = parseInt(day, 10);

  // Validate the month and day ranges
  if (month_index < 0 || month_index > 11 || day_int < 1 || day_int > 31) {
    return "(Error)";
  }

  // Check for invalid dates (e.g., Feb. 30)
  const date = new Date(year, month_index, day_int);
  if (
    date.getFullYear().toString() !== year ||
    date.getMonth() !== month_index ||
    date.getDate() !== day_int
  ) {
    return "(Error)";
  }

  // Format the birthday
  return `${month_names[month_index]} ${day_int}, ${year}`;
};



export const day_map = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};


export const receptionist_action_map = {
  schedule_appointment: "Schedule an appointment",
  
}