
import { useEffect, useState } from 'react'


import ReactLoading from "react-loading"
import { useGlobal } from '../../../contexts/GlobalContext'

import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

import { useLocation, Link } from "react-router-dom"

import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { classNames } from '../../../libs/helpers';
import { get_user_property_value } from '../../../libs/formats'





const AccountPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()

  // User data
  const [user_data, set_user_data] = useState({})
  const [user_data_is_fetched, set_user_data_is_fetched] = useState(false)

  


  const get_user_data = async () => {
    // Set is_fetched to false
    set_user_data_is_fetched(false)

    // Execute get user data
    const get_user_data_res = await auth_axios.get(`/api/users/${global_context.user_id}`)

    if (!get_user_data_res.data.success) {
      switch (get_user_data_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_user_data(get_user_data_res.data.user_data)
    set_user_data_is_fetched(true)
  }



  // Renders 
  useEffect(() => {

    get_user_data()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_account_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          
          {/* Header */}
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Profile
              </h1>
              <div className="mt-2 text-sm text-gray-700">
                View and edit your profile
              </div>
            </div>
            <Link
              type="button"
              className={classNames(!user_data_is_fetched ? "bg-blue-500" : "bg-blue-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")} 
              to={`update/profile`}
            >
              Update profile
            </Link>
            
          </div>

          {/* Data */}
          <div className="mt-8 flow-root">

            {/* User information */}
            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

              {/* First name */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">First name</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "first_name", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                </dd>
              </div>

              {/* Last name */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Last name</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "last_name", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                </dd>
              </div>

              {/* Email address */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "email_address", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                  <Link to="update/email" className="font-semibold text-blue-600 hover:text-blue-500">
                    Update
                  </Link>
                </dd>
              </div>

              {/* Phone number */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone number</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "phone_number", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                  <Link to="update/phone" className="font-semibold text-blue-600 hover:text-blue-500">
                    Update
                  </Link>
                </dd>
              </div>

              {/* Birthday */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Birthday</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "birthday", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                </dd>
              </div>

              {/* Sex */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Sex</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "sex", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                </dd>
              </div>

              {/* Authentication type */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Login method</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {user_data_is_fetched ? (
                      <span>{get_user_property_value(user_data, "authentication_type", true)}</span>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>
                </dd>
              </div>

              {/* Password */}
              {user_data_is_fetched && user_data["authentication_metadata"]["type"] === "password" ? (
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{user_data["authentication_metadata"]["password_is_set"] ? "********" : "Not applicable"}</div>
                    {user_data["authentication_metadata"]["password_is_set"] ? (
                      <Link to="update/password" className="font-semibold text-blue-600 hover:text-blue-500">
                        Update
                      </Link>
                    ) : (<></>)}
                  </dd>
                </div>
              ) : (
                <></>
              )}
              
              {/* SSO method */}
              {user_data_is_fetched && user_data["authentication_metadata"]["type"] === "sso" ? (
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">SSO method</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      {user_data_is_fetched ? (
                        <span>{get_user_property_value(user_data, "authentication_sso_method", true)}</span>
                      ) : (
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />)}
                    </div>
                  </dd>
                </div>
              ) : (
                <></>
              )}

              {/* <Disclosure as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                        <span className="font-medium text-sm">Additional properties</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                          ) : (
                            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="div" className="mt-8 space-y-6 divide-y divide-gray-100 text-sm leading-6">


                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Department</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {user_data_is_fetched ? (
                              <span>{(user_data["user_metadata"]["department"] && !user_data["user_metadata"]["department"].startsWith("UNSET")) ? user_data["user_metadata"]["department"] : "(Unspecified)"}</span>
                            ) : (
                            <ReactLoading
                              type='spokes'
                              color='#343D46'
                              height={20}
                              width={20}
                            />)}
                          </div>
                        </dd>
                      </div>





                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure> */}

              {/* Delete profile */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-red-400">Delete profile</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">To close your profile, please reach out to support@aldermate.com</div>
                </dd>
              </div>
            </dl>



          </div>

          
        </div>

      </div>
    </div>
  )
}

export default AccountPage