import { IS_DEV } from './env';


// Mixpanel
import mixpanel_client from 'mixpanel-browser' // client
mixpanel_client.init("8085a169ccce5f5f4f40fa0d04b5cf92") // client



// CLIENT
export const mixpanel_client_track = (event_name, user_id) => {
  mixpanel_client.track(event_name, {   
    distinct_id: user_id, // if null, then it'll be an anonymous user
    is_dev: IS_DEV
  });
}