
import { Navigate, useLocation, Outlet, useNavigate } from "react-router-dom"

import { useGlobal } from '../contexts/GlobalContext';
import { ProtectedProvider } from "../contexts/ProtectedContext";


import Notifications from '../components/Notifications';
import Banners from "../components/Banners";
import Navbar from "../components/Navbar";


const ProtectedLayout = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // States



  if(!global_context.authenticated) {
    return <Navigate to="/login" state={{from: location}} />;
  }

  return (
    <ProtectedProvider>

      {/* Notifications */}
      <Notifications />

      {/* Banners */}
      <Banners />

      {/* Navbar */}
      {/* <Navbar /> */}

      {/* Outlet */}
      <Outlet />

    </ProtectedProvider>
  )
}

export default ProtectedLayout;
