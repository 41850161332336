
import { useEffect, useState } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import { useLocation, Link } from "react-router-dom"

import { auth_axios } from '../../../libs/authWeb';
import { get_date_string_short } from '../../../libs/formats';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { beautify_usd_amount, get_account_status_pretty, get_invoice_status } from '../../../libs/formats';
import { get_payment_method_pretty } from '../../../libs/jsxFormats';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'



const StripeInvoice = ({
  invoice
} : {
  invoice : any
}) => {
  return (
    <div className="px-4 flex justify-between">
      {invoice.status !== "draft"
      ? <Link to={invoice.hosted_invoice_url} target="_blank" className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-600 hover:text-blue-900 sm:pl-0">
          {get_date_string_short(invoice.period_end * 1000)}
        </Link>
      : <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-800 hover:text-gray-900 sm:pl-0">{get_date_string_short(invoice.period_end * 1000)}</div>}
      <div className={invoice.status === "void" ? "whitespace-nowrap px-3 py-4 text-sm text-gray-500 line-through" : "whitespace-nowrap px-3 py-4 text-sm text-gray-500"}>{beautify_usd_amount(invoice.total)}</div>
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{get_invoice_status(invoice.total, invoice.status)}</div>
      <div className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        {invoice.status !== "draft"
        ? invoice.status === "open"
          ? <Link to={invoice.hosted_invoice_url} target="_blank" className="text-red-400 hover:text-red-500">
              Settle invoice
            </Link>
          : <Link to={invoice.invoice_pdf} target="_blank" className="text-blue-600 hover:text-blue-900">
              Download invoice
            </Link>
        : <div>Not available</div>}
      </div>
    </div>
  )
}


const BillingPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // User data
  const [account_data, set_account_data] = useState({})
  const [companions, set_companions] = useState([])
  const [account_data_and_companions_are_fetched, set_account_data_and_companions_are_fetched] = useState(false)


  const get_account_and_companions = async () => {
    // Set is_fetched to false
    set_account_data_and_companions_are_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/accounts`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get companion data
    const get_org_companions_res = await auth_axios.get(`/api/companions`)

    if (!get_org_companions_res.data.success) {
      switch (get_org_companions_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_account_data(get_org_res.data.account_data)
    set_companions(get_org_companions_res.data.companions)
    set_account_data_and_companions_are_fetched(true)
  }



  // Renders 
  useEffect(() => {

    get_account_and_companions()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_billing_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Billing</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Billing information for your account
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

            {/* Status */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account status</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {account_data_and_companions_are_fetched ? (
                    <span>
                      {get_account_status_pretty(account_data["status"])}
                      {account_data["status"] === "inactive" ? <><br/><div className="mt-4 text-gray-500">To activate your account, add payment method below</div></> : ""}
                    </span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* <Link to="update/status" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link> */}
              </dd>
            </div>

            {/* Account credit / balance */}
            {account_data_and_companions_are_fetched && account_data["stripe_metadata"]["balance"]
              // Account credit
            ? account_data["stripe_metadata"]["balance"] < 0
              ? <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account Credit</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      {beautify_usd_amount(account_data["stripe_metadata"]["balance"]).slice(1)}
                      <div className="mt-4 text-gray-500">This credit will be applied to your next invoice</div>
                    </div>
                  </dd>  
                </div>
              // Account balance
              : account_data["stripe_metadata"]["balance"] > 0 
                ? <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account Balance</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {beautify_usd_amount(account_data["stripe_metadata"]["balance"])}
                        <div className="mt-4 text-gray-500">This balance will be added as a surcharge in your next invoice</div>
                      </div>
                    </dd>  
                  </div>
                  // Don't show anything if account balance is 0
                : <></>
              // Don't show anything if account balance is null 
            : <></> }

            {/* Payment method */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Payment method</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {account_data_and_companions_are_fetched ? (
                    <span>
                      {get_payment_method_pretty(account_data)}
                      {/* {account_data["billing_metadata"]["payment_method"].startsWith("UNSET") ? <><br/><div className="mt-4 text-gray-500">Please contact support@aldermate.com for custom contract and payment method</div></> : ""} */}
                    </span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>

                {/* Show update button only if current payment method is not set or is Stripe */}
                {account_data_and_companions_are_fetched && ["UNSET", "stripe"].includes(account_data["billing_metadata"]["payment_method"])
                ? <Link to="update/payment" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link>
                : <></>}
              </dd>  
            </div>


            {/* Account-specific */}
            {account_data["status"] === "active" && account_data["billing_metadata"]["payment_method"] === "stripe" && !account_data["billing_metadata"]["billing_cycle"].startsWith("UNSET") ? (
              <div>
                {/* Title */}
                <div className="pt-6 font-bold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account</div>

                {/* Billing cycle */}
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Billing cycle</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      {account_data_and_companions_are_fetched ? (
                        <span>{account_data["billing_metadata"]["num_seats"]}{account_data["billing_metadata"]["billing_cycle"].startsWith("UNSET") ? " seat(s)" : ` seat(s), billed ${account_data["billing_metadata"]["billing_cycle"]}`}</span>
                      ) : (
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />)}
                    </div>

                    {/* Show update button only if current payment method is Stripe */}
                    {account_data_and_companions_are_fetched && ["stripe"].includes(account_data["billing_metadata"]["payment_method"])
                    ? <Link to="update/plan" className="font-semibold text-blue-600 hover:text-blue-500">
                      Update
                    </Link>
                    : <></>}
                  </dd>
                </div>

                {/* Invoices */}
                <Disclosure as="div" className="pt-6" defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                          <span className="font-medium text-sm">{`Invoices (Account)`}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                            ) : (
                              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="div" className="mt-2 divide-y divide-gray-300 bg-gray-50 rounded-lg">
                        <div className="divide-y divide-gray-200 max-h-[500px] overflow-y-auto">
                          {account_data_and_companions_are_fetched
                          ? !account_data["stripe_metadata"]["invoice_metadata"]["account"]["upcoming_invoice"] || account_data["stripe_metadata"]["invoice_metadata"]["account"]["past_invoices"].length === 0
                            ? <div className="w-full px-4 py-2">No invoice to show</div>
                            : <>
                                {/* Upcoming invoice */}
                                <StripeInvoice key={account_data["stripe_metadata"]["invoice_metadata"]["account"]["upcoming_invoice"]["id"]} invoice={account_data["stripe_metadata"]["invoice_metadata"]["account"]["upcoming_invoice"]}/>
                                {/* Past invoices */}
                                {account_data["stripe_metadata"]["invoice_metadata"]["account"]["past_invoices"].map((invoice) => (
                                  <StripeInvoice key={invoice.id} invoice={invoice}/>
                                ))}
                              </>
                          : <div className="w-full py-4 flex justify-center"> 
                              <ReactLoading
                                type='spokes'
                                color='#343D46'
                                height={20}
                                width={20}
                              />
                            </div>
                          }
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
              
            ) : (
              <></>
            )}

            {/* Companions */}
            {account_data["status"] === "active" && account_data["billing_metadata"]["payment_method"] === "stripe" ? (
              companions.map(companion => (
                <div key={companion.companion_id}>
                  {/* Title */}
                  <div className="pt-6 font-bold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{companion.companion_metadata.first_name}</div>

                  {/* Billing cycle */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Billing cycle</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {account_data_and_companions_are_fetched ? (
                          <span>{companion["billing_metadata"]["num_seats"]}{companion["billing_metadata"]["billing_cycle"].startsWith("UNSET") ? " seat(s)" : ` seat(s), billed ${companion["billing_metadata"]["billing_cycle"]}`}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>

                      {/* Show update button only if current payment method is Stripe */}
                      {account_data_and_companions_are_fetched && ["stripe"].includes(account_data["billing_metadata"]["payment_method"])
                      ? <Link to={`/dashboard/companions/${companion.companion_id}/edit`} className="font-semibold text-blue-600 hover:text-blue-500">
                        Update
                      </Link>
                      : <></>}
                    </dd>
                  </div>

                  {/* Invoices */}
                  <Disclosure as="div" className="pt-6" defaultOpen={true}>
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                            <span className="font-medium text-sm">{`Invoices`}</span>
                            <span className="ml-6 flex h-7 items-center">
                              {open ? (
                                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                              ) : (
                                <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="div" className="mt-2 divide-y divide-gray-300 bg-gray-50 rounded-lg">
                          <div className="divide-y divide-gray-200 max-h-[500px] overflow-y-auto">
                            {account_data_and_companions_are_fetched
                            ? !account_data["stripe_metadata"]["invoice_metadata"][companion.companion_id]["upcoming_invoice"] || account_data["stripe_metadata"]["invoice_metadata"][companion.companion_id]["past_invoices"].length === 0
                              ? <div className="w-full px-4 py-2">No invoice to show</div>
                              : <>
                                  {/* Upcoming invoice */}
                                  <StripeInvoice key={account_data["stripe_metadata"]["invoice_metadata"][companion.companion_id]["upcoming_invoice"]["id"]} invoice={account_data["stripe_metadata"]["invoice_metadata"][companion.companion_id]["upcoming_invoice"]}/>
                                  {/* Past invoices */}
                                  {account_data["stripe_metadata"]["invoice_metadata"][companion.companion_id]["past_invoices"].map((invoice) => (
                                    <StripeInvoice key={invoice.id} invoice={invoice}/>
                                  ))}
                                </>
                            : <div className="w-full py-4 flex justify-center"> 
                                <ReactLoading
                                  type='spokes'
                                  color='#343D46'
                                  height={20}
                                  width={20}
                                />
                              </div>
                            }
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ))
            ) : (
              <></>
            )}

            {/* All invoices */}
            {account_data["status"] === "active" && account_data["billing_metadata"]["payment_method"] === "stripe" ? (
              <div>
                {/* Title */}
                <div className="pt-6 font-bold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">All invoices</div>

                {/* Invoices */}
                <Disclosure as="div" className="pt-6" defaultOpen={false}>
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                          <span className="font-medium text-sm">{`Invoices`}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                            ) : (
                              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="div" className="mt-2 divide-y divide-gray-300 bg-gray-50 rounded-lg">
                        <div className="divide-y divide-gray-200 max-h-[500px] overflow-y-auto">
                          {account_data_and_companions_are_fetched
                          ? !account_data["stripe_metadata"]["invoice_metadata"]["all"]["upcoming_invoice"] || account_data["stripe_metadata"]["invoice_metadata"]["all"]["past_invoices"].length === 0
                            ? <div className="w-full px-4 py-2">No invoice to show</div>
                            : <>
                                {/* Upcoming invoice */}
                                <StripeInvoice key={account_data["stripe_metadata"]["invoice_metadata"]["all"]["upcoming_invoice"]["id"]} invoice={account_data["stripe_metadata"]["invoice_metadata"]["all"]["upcoming_invoice"]}/>
                                {/* Past invoices */}
                                {account_data["stripe_metadata"]["invoice_metadata"]["all"]["past_invoices"].map((invoice) => (
                                  <StripeInvoice key={invoice.id} invoice={invoice}/>
                                ))}
                              </>
                          : <div className="w-full py-4 flex justify-center"> 
                              <ReactLoading
                                type='spokes'
                                color='#343D46'
                                height={20}
                                width={20}
                              />
                            </div>
                          }
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ) : (
              <></>
            )}

          </dl>
        </div>

      </div>
    </div>
  )
}

export default BillingPage