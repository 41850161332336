
import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'


import { useLocation, useNavigate } from "react-router-dom"

import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'


import { auth_axios } from '../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames } from '../../../../../libs/helpers';
import { validate_email_address } from '../../../../../libs/validate';

import { mixpanel_client_track } from '../../../../../libs/mixpanelClient'
import OTP from '../../../../../components/OTP'








const AccountUpdateEmailPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()



  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status 
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  const [is_awaiting, set_is_awaiting] = useState(false)
  const [email_validation_token_id_is_fetched, set_email_validation_token_id_is_fetched] = useState(false)
  const [email_address_is_updated, set_email_address_is_updated] = useState(false)
  
  

  // NEW EMAIL ADDRESS

  // User input
  const [email_address, set_email_address] = useState("")
  const email_address_ref = useRef<any>(null);

  const [email_validation_token_id, set_email_validation_token_id] = useState("")

  // CODE

  // User input
  // const [code, set_code] = useState("")
  // const code_ref = useRef<any>(null);

  const [otp, set_otp] = useState(Array(6).fill("")); // Array with 6 empty strings
  const otp_input_refs = useRef([]);
  


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "email_address": {
        set_email_address(value)
  
        // Always break
        break
      }
      // case "code": {
      //   set_code(value)
  
      //   // Always break
      //   break
      // }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  // Generate code
  const generate_code = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Invalid email address")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute fetch email_validation_token_id
    const post_email_validation_token_res = await auth_axios.post(`/api/email-validation-tokens`, {
      user_id: global_context.user_id,
      email_address: email_address, // new email address
      type: "email_confirmation"
    })

    if (!post_email_validation_token_res.data.success) {
      switch (post_email_validation_token_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/account page
          navigate(`/dashboard/account`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_email_validation_token_res.data.status) {
      case "SUCCESS": {
        set_email_validation_token_id_is_fetched(true)
        set_is_awaiting(false)
        set_email_validation_token_id(post_email_validation_token_res.data.email_validation_token_id)

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        set_error_message(post_email_validation_token_res.data.error_message)
        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }


  // Submit code to update email address
  const submit_code = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // Join code into a single string
    const code = otp.join("")

    // START OF USER INPUT CHECK

    // Validate code

    if (code.length === 0) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please enter the code.")

      // End of the line
      return
    }

    if (code.length !== 6) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Incorrect code")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update email
    const put_user_email_res = await auth_axios.put(`/api/users/${global_context.user_id}/email`, {
      email_validation_token_id: email_validation_token_id,
      code: code,
    })

    if (!put_user_email_res.data.success) {
      switch (put_user_email_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/account page
          navigate(`/dashboard/account`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_user_email_res.data.status) {
      case "SUCCESS": {
        // set_email_address_is_updated(true)
        // set_is_awaiting(false)
        // set_success_message("Your email has been updated.")

        show_notification(protected_context, protected_update, "success", "Success", "Your email has been updated.")

        // Redirect to dashboard account
        navigate(`/dashboard/account`)

        // Always break
        break
      }
      case "TOKEN_DOES_NOT_EXIST":
      case "USER_ID_DOES_NOT_MATCH":
      case "TOKEN_TYPE_IS_WRONG": {
        // alert("An error occured. Please try again.")
        show_notification(protected_context, protected_update, "error", "Error", "An error occured. Please try again.")

        // Redirect to dashboard account
        navigate(`/dashboard/account`)

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        // set_error_message(put_user_email_res.data.error_message)
        show_notification(protected_context, protected_update, "error", "Error", put_user_email_res.data.error_message)
        
        // Redirect to dashboard account
        navigate(`/dashboard/account`)

        // Always break
        break
      }
      case "CODE_IS_INCORRECT": {
        // Show error message
        set_error_message("Incorrect code")

        // Reset 
        set_is_awaiting(false)
        
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders 
  useEffect(() => { 

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_account_update_email_visited", global_context.user_id)
    
  }, [])


  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Update email address</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update your email address
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-[400px] justify-center items-center">
              {!email_validation_token_id_is_fetched
              ? <div className="w-full space-y-6"> 
                  <div>
                    <label htmlFor="email" className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-medium">New email address</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          You must be the owner of this email address.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={email_address}
                        onChange={(e) => handle_user_input("email_address", e.target.value)}
                        onKeyDown={(e) => handle_enter_keydown(e, generate_code)}
                        disabled={is_awaiting || email_validation_token_id_is_fetched}
                      />
                    </div>
                  </div>

                  {error_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <div>
                        <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                      </div>
                      <div className="text-sm font-medium text-red-400">{error_message}</div>
                    </div>
                  : <></>}
                  
                  <button 
                    className={classNames(
                      is_awaiting || email_validation_token_id_is_fetched ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={generate_code}
                    disabled={is_awaiting || email_validation_token_id_is_fetched}
                  >
                    {!is_awaiting
                    ? <span>Send verification code</span>
                    :<ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />}
                  </button> 
                </div>
              : <div className="w-full space-y-6">
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-medium">Verification code</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Please check your inbox. If you don't see the verification code email in your inbox, please check your promotions tab & spam folder as well.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      {/* <input
                        type="tel"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={code}
                        onChange={(e) => handle_user_input("code", e.target.value)}
                        onKeyDown={(e) => handle_enter_keydown(e, submit_code)}
                        disabled={is_awaiting || email_address_is_updated}
                      /> */}
                      <OTP otp={otp} set_otp={set_otp} otp_input_refs={otp_input_refs} submit_fn={submit_code} />
                    </div>
                  </div>

                  {error_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <div>
                        <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                      </div>
                      <div className="text-sm font-medium text-red-400">{error_message}</div>
                    </div>
                  : <></>}
                  {email_address_is_updated && success_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <div>
                        <CheckCircleIcon className="pt-[3px] w-4 h-4 text-green-600 h-full"/>
                      </div>
                      <div className="text-sm font-medium text-green-600">{success_message}</div>
                    </div>
                  : <></>}
                  
                  <button 
                    className={classNames(
                      is_awaiting || email_address_is_updated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={submit_code}
                    disabled={is_awaiting || email_address_is_updated}
                  >
                    {!is_awaiting
                    ? <span>Update email address</span>
                    :<ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />}
                  </button>
                </div>
              }
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default AccountUpdateEmailPage