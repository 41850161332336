
import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import {  useLocation, useNavigate } from "react-router-dom"

import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'


import { HASH } from '../../../../../libs/crypto'
import { string_to_uint8_array, uint8_array_to_base64 } from '../../../../../libs/converters'

import axios from "axios"

import { auth_axios } from '../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames } from '../../../../../libs/helpers';
import { validate_password } from '../../../../../libs/validate';
import { mixpanel_client_track } from '../../../../../libs/mixpanelClient'




const AccountUpdatePasswordPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")
  
  // User input
  const [old_password, set_old_password] = useState("")
  const old_password_ref = useRef<any>(null);

  const [new_password, set_new_password] = useState("")
  const [new_password_confirm, set_new_password_confirm] = useState("")
  const new_password_ref = useRef<any>(null);

  const [password_reset_token_id, set_password_reset_token_id] = useState({})

  // Statuses
  const [is_awaiting, set_is_awaiting] = useState(false)

  const [password_reset_token_id_is_fetched, set_password_reset_token_id_is_fetched] = useState(false)
  const [password_is_reset, set_password_is_reset] = useState(false)



  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "old_password": {
        set_old_password(value)
  
        // Always break
        break
      }
      case "new_password": {
        set_new_password(value)
  
        // Always break
        break
      }
      case "new_password_confirm": {
        set_new_password_confirm(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }



  const get_password_reset_token_id = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // Hash master password prepare for API call
    const password__unenc__uint8_array = string_to_uint8_array(old_password)
    const password__hash__uint8_array = await HASH(password__unenc__uint8_array)
    const password__hash__base64 = uint8_array_to_base64(password__hash__uint8_array)

    // Execute get user data
    const post_password_reset_token_res = await auth_axios.post(`/api/password-reset-tokens`, {
      user_id: global_context.user_id,
      type: "dashboard_update",
      password__hash: password__hash__base64,
    })

    if (!post_password_reset_token_res.data.success) {
      switch (post_password_reset_token_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_password_reset_token_res.data.status) {
      case "SUCCESS": {
        set_password_reset_token_id(post_password_reset_token_res.data.password_reset_token_id)
        set_password_reset_token_id_is_fetched(true)
        set_is_awaiting(false)

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        set_error_message(post_password_reset_token_res.data.error_message)
        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }


    
    // Set states
    
  }

  // Reset password
  const reset_password = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate password
    if (!validate_password(new_password)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Password must be at least 8 characters long and must include a lowercase alphabet, an uppercase alphabet, a number, and a special character")

      // End of the line
      return
    }

    if (new_password !== new_password_confirm) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Passwords do not match")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Hash master password prepare for API call
    const password__unenc__uint8_array = string_to_uint8_array(new_password)
    const password__hash__uint8_array = await HASH(password__unenc__uint8_array)
    const password__hash__base64 = uint8_array_to_base64(password__hash__uint8_array)

    // Execute reset password
    const put_user_password_res = await axios.put(`/api/users/${global_context.user_id}/password`, {
      password_reset_token_id: password_reset_token_id,
      type: "dashboard_update",
      password__hash: password__hash__base64
    })

    if (!put_user_password_res.data.success) {
      switch (put_user_password_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/account page
          navigate(`/dashboard/account`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_user_password_res.data.status) {
      case "SUCCESS": {
        // set_password_is_reset(true)
        // set_is_awaiting(false)
        // set_success_message("Your password has been reset")

        show_notification(protected_context, protected_update, "success", "Success", "Your password has been reset.")

        navigate(`/dashboard/account`)

        // Always break
        break
      }
      case "USER_ID_DOES_NOT_MATCH":
      case "TOKEN_TYPE_IS_WRONG": {
        // alert("Error occurred with password reset. Please try again")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred with password reset. Please try again.")

        navigate(`/dashboard/account`)

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        // set_error_message(put_user_email_res.data.error_message)
        show_notification(protected_context, protected_update, "error", "Error", put_user_password_res.data.error_message)
        
        // Redirect to dashboard account
        navigate(`/dashboard/account`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders 
  useEffect(() => { 

    // Focus on old password field
    old_password_ref.current.focus()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_account_update_password_visited", global_context.user_id)
    
  }, [])

  // Focus on the password field
  useEffect(() => {
    if (password_reset_token_id_is_fetched) {
      new_password_ref.current.focus()
    }
  }, [password_reset_token_id_is_fetched])

  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Update password</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update your password
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-[400px] justify-center items-center">
              {password_reset_token_id_is_fetched
              ? <div className="w-full space-y-4">
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-medium">New password</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Password must be at least 8 characters long and must include a lowercase alphabet, an uppercase alphabet, a number, and a special character.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        ref={new_password_ref}
                        value={new_password}
                        onChange={(e) => handle_user_input("new_password", e.target.value)}
                        onKeyDown={(e) => handle_enter_keydown(e, reset_password)}
                        disabled={is_awaiting || password_is_reset}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Confirm new password
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={new_password_confirm}
                        onChange={(e) => handle_user_input("new_password_confirm", e.target.value)}
                        onKeyDown={(e) => handle_enter_keydown(e, reset_password)}
                        disabled={is_awaiting || password_is_reset}
                      />
                    </div>
                  </div>

                  {error_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <div>
                        <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                      </div>
                      <div className="text-sm font-medium text-red-400">{error_message}</div>
                    </div>
                  : <></>}
                  {password_is_reset && success_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <div>
                        <CheckCircleIcon className="pt-[3px] w-4 h-4 text-green-600 h-full"/>
                      </div>
                      <div className="text-sm font-medium text-green-600">{success_message}</div>
                    </div>
                  : <></>}
                  
                  <button 
                    className={classNames(
                      is_awaiting || password_is_reset ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={reset_password}
                    disabled={is_awaiting || password_is_reset}
                  >
                    {!is_awaiting
                    ? <span>Update password</span>
                    :<ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />}
                  </button> 
                </div>
              : <div className="w-full">
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Current password
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        ref={old_password_ref}
                        value={old_password}
                        onChange={(e) => handle_user_input("old_password", e.target.value)}
                        onKeyDown={(e) => handle_enter_keydown(e, get_password_reset_token_id)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {error_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <div>
                        <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                      </div>
                      <div className="text-sm font-medium text-red-400">{error_message}</div>
                    </div>
                  : <></>}
                  
                  <button 
                    className={classNames(
                      is_awaiting ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={get_password_reset_token_id}
                    disabled={is_awaiting}
                  >
                    {!is_awaiting
                    ? <span>Continue</span>
                    :<ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />}
                  </button> 
                </div>
              } 
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default AccountUpdatePasswordPage