
import { useEffect, useState, useRef, Fragment } from 'react'
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom"


import { Transition, Listbox, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { ArrowLongLeftIcon, InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import axios from "axios"

import { set as idb_set } from 'idb-keyval'
import { ResponsiveLogoMarkOnly } from '../../../components/logo'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import { handle_enter_keydown, classNames } from '../../../libs/helpers'
import { validate_email_address } from '../../../libs/validate'

import { mixpanel_client_track } from '../../../libs/mixpanelClient'




const OAuthGooglePage = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Search params
  const [search_params, _] = useSearchParams()
  
  // User input
  const [email_address, set_email_address] = useState("")
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")

  const [user_id, set_user_id] = useState("")
  const [account_id, set_account_id] = useState("")

  const [phone_number, set_phone_number] = useState("")
  const [birthday, set_birthday] = useState("")
  const [sex, set_sex] = useState("")

  const [is_agreed, set_is_agreed] = useState(false)

  // Sign up user type
  const [is_owner_signup, set_is_owner_signup] = useState(true)

  // Autofocus on account name and email address field
  const email_address_ref = useRef<any>(null)


  // Error message
  const [error_message, set_error_message] = useState("")

  // Statuses
  const [is_validated, set_is_validated] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Validate search parameters
  const validate_params = async () => {
    const email_validation_token_id = search_params.get("email_validation_token_id")
    const code = search_params.get("code")

    // Execute validate email_validation_token
    const post_validate_email_validation_token_res = await axios.post(`/api/email-validation-tokens/${email_validation_token_id}/validate`, {
      code: code,
      type: "google_oauth",
    })

    if (!post_validate_email_validation_token_res.data.success) {
      switch (post_validate_email_validation_token_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to login page
          navigate(`/login`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_validate_email_validation_token_res.data.status) {
      case "VALID": {
        set_is_owner_signup(post_validate_email_validation_token_res.data.is_owner_signup)
        set_user_id(post_validate_email_validation_token_res.data.user_id)
        set_account_id(post_validate_email_validation_token_res.data.account_id)
        set_email_address(post_validate_email_validation_token_res.data.email_address) // modifiable
        set_first_name(post_validate_email_validation_token_res.data.first_name) // modifiable
        set_last_name(post_validate_email_validation_token_res.data.last_name) // modifiable
        set_phone_number(post_validate_email_validation_token_res.data.phone_number)
        set_birthday(post_validate_email_validation_token_res.data.birthday)
        set_sex(post_validate_email_validation_token_res.data.sex)

        // Always break
        break
      }
      case "AUTHENTICATED": {

        // Save JWT in idb-keyval
        await idb_set("session_token", post_validate_email_validation_token_res.data.session_token)

        // Global variables
        global_update({ 
          ...global_context, 
          authenticated: true,
          user_id: post_validate_email_validation_token_res.data.user_id,
          type: post_validate_email_validation_token_res.data.type,
        })
  
        // If owner or admin, navigate to dashboard page
        if (["owner", "admin"].includes(post_validate_email_validation_token_res.data.type)) {
          navigate(location.state ? location.state.from : `/dashboard`)
        }
        // If not owner or admin, navigate to dashboard page
        else {
          navigate(location.state ? location.state.from : `/dashboard`)
        }

        // Always break
        break
      }
      case "INACTIVE_USER": {
        alert("Your account is inactive. Please contact your admin.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "WRONG_AUTHENTICATION_TYPE": {
        alert("You cannot use SSO on this account.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "SSO_METHOD_IS_WRONG": {
        alert("SSO method is wrong.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "CANNOT_USE_THIS_EMAIL": {
        alert("You cannot use this email. Please try again.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "EMAIL_ADDRESS_IS_INVALID": {
        alert("Invalid email address. Please try again.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "INVALID": {
        alert("Invalid link. Please try again.")

        // Redirect to vanilla reset page
        navigate(`/login`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Toggle
    set_is_validated(true)

  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "first_name": {
        set_first_name(value)

        // Always break
        break
      }
      case "last_name": {
        set_last_name(value)

        // Always break
        break
      }
      case "is_agreed": {
        set_is_agreed(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  // Submit function
  const submit = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If non-owner signup, user_id and account_id must be valid
    if (!is_owner_signup) {
      
      // Check user_id and account_id
      if (!(user_id && account_id)) {
        // Show error message
        set_error_message("Invalid link")

        // End of the line
        return
      } 

    }

    // Validate email address
    if (!validate_email_address(email_address)) {
      reset()

      // Show error message
      set_error_message("Invalid email address")

      // End of the line
      return
    }

    // Validate first name
    if (!first_name) {
      reset()

      // Show error message
      set_error_message("Please enter your first name")

      // End of the line
      return
    }

    // Validate last name
    if (!last_name) {
      reset()

      // Show error message
      set_error_message("Please enter your last name")

      // End of the line
      return
    }

    // Validate is_agreed
    if (!is_agreed) {
      reset()

      // Show error message
      set_error_message("Please agree to the Terms of Service and Privacy Policy")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK


    // Execute signup
    const post_auth_google_signup_res = await axios.post(`/api/auth/google/signup`, {
      is_owner_signup: is_owner_signup,
      user_id: user_id,
      account_id: account_id,
      email_address: email_address, // modifiable
      phone_number: phone_number,
      first_name: first_name, // modifiable
      last_name: last_name, // modifiable
      birthday: birthday,
      sex: sex,
    })

    if (!post_auth_google_signup_res.data.success) {
      switch (post_auth_google_signup_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to signup
          navigate(`/signup`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_google_signup_res.data.status) {
      case "AUTHENTICATED": {
        // Save JWT in idb-keyval
        await idb_set("session_token", post_auth_google_signup_res.data.session_token)

        // Global variables
        global_update({ 
          ...global_context, 
          authenticated: true,
          user_id: post_auth_google_signup_res.data.user_id,
          type: post_auth_google_signup_res.data.type
        })
  
        // ___??___ This doesn't appear to be necessary, but just having it here for safety and for code readability
        // navigate(location.state ? location.state.from : `/onboarding`)

        // If owner, navigate to add users page
        if (post_auth_google_signup_res.data.type === "owner") {
          navigate(`/dashboard`)
        }
        // If admin, navigate to the campaigns page
        else if (post_auth_google_signup_res.data.type === "admin") {
          navigate(`/dashboard`)
        }
        // If not owner or admin, navigate to the account page
        else {
          navigate(`/dashboard`)
        }
        
        // Always break
        break
      }
      case "ACCOUNT_AND_USER_EXISTS": {
        alert("You already have an account. Please login.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "ACCOUNT_EXISTS_AND_UNWELCOME_EMAIL_ADDRESS": {
        alert("Your account already has an account. Please contact your admin.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "WRONG_AUTHENTICATION_TYPE": {
        alert("You chose the wrong authentication method. Please try again")

        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }





  // Renders
  useEffect(() => {

    // Validate search params
    validate_params()
    
    // Mixpanel tracking
    mixpanel_client_track("app_google_visited", null)
    
  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      {is_validated 
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
            <ResponsiveLogoMarkOnly className="h-8 w-auto" />
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

            <div className="space-y-4">
              
              {/* Account name */}
              {/* {is_owner_signup
              ? <div>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-medium">Company name</span>
                  <span className="flex relative items-center">
                    <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      The legal name of your company. This can be changed later.
                    </span>
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    ref={account_name_ref}
                    value={account_name}
                    onChange={(e) => handle_user_input("account_name", e.target.value)}
                  />
                </div>
              </div>
              : <></>} */}

              {/* Email address */}
              <div>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-medium">Email address</span>
                  <span className="flex relative items-center">
                    {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      Email address for your account that will be used for owner (admin) privileges. Your email must belong to your company domain.
                    </span> */}
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-gray-100"
                    ref={email_address_ref}
                    value={email_address}
                    disabled // Email cannot be changed
                  />
                </div>
              </div>

              {/* First name */}
              <div>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-medium">First name</span>
                  <span className="flex relative items-center">
                    {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      The domain of your company. Only those with email address belonging to this domain are allowed to be added to your account. This cannot be changed later.
                    </span> */}
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    value={first_name}
                    onChange={(e) => handle_user_input("first_name", e.target.value)}
                    onKeyDown={(e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>

              {/* Last name */}
              <div>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-medium">Last name</span>
                  <span className="flex relative items-center">
                    {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      The domain of your company. Only those with email address belonging to this domain are allowed to be added to your account. This cannot be changed later.
                    </span> */}
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    value={last_name}
                    onChange={(e) => handle_user_input("last_name", e.target.value)}
                    onKeyDown={(e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>
              
              {/* Agree */}
              <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center">
                  <Switch
                    checked={is_agreed}
                    onChange={(e) => handle_user_input("is_agreed", e)}
                    className={classNames(
                      is_agreed ? 'bg-blue-600' : 'bg-gray-200',
                      'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        is_agreed ? 'translate-x-3.5' : 'translate-x-0',
                        'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </div>
                <div>
                  <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                    By selecting this, you agree to our{' '}
                  </Switch.Label>
                  <Link to="https://app.aldermate.com/terms" target="_blank" className="font-semibold text-blue-600 text-sm leading-6">
                    Terms&nbsp;of&nbsp;Service
                  </Link>
                  <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                    {" "}and{" "}
                  </Switch.Label>
                  <Link to="https://app.aldermate.com/privacy" target="_blank" className="font-semibold text-blue-600 text-sm leading-6">
                    Privacy&nbsp;Policy
                  </Link>
                  <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                    .
                  </Switch.Label>
                </div>
                
              </Switch.Group>

              {/* Error message */}
              {error_message
              ? <div className="flex space-x-2 items-start">
                  <div>
                    <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                  </div>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}

              {/* Submit button */}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={submit}
                >
                  {is_awaiting ? (
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={20}
                      width={20}
                    />
                  ) : (
                    <span>Create account</span>
                  )}
                </button>
              </div>

            </div>

            {/* <p className="mt-10 text-center text-sm text-gray-500">
              Already have an account?{" "}
              <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Log in
              </Link>
            </p> */}
          </div>
        </div>
      : <ReactLoading
        type='spokes'
        color='#343D46'
        height={20}
        width={20}
      />
      }
    </div>
  )
}

export default OAuthGooglePage