
import { useEffect } from 'react'


import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../contexts/ProtectedContext'

import { classNames } from '../../libs/helpers'
import { set as idb_set } from 'idb-keyval';

import { ResponsiveLogo } from '../../components/logo';


import { useLocation, useNavigate, Outlet, Link } from "react-router-dom"

import { browserName, isMobile, isDesktop } from "react-device-detect";

import {
  CreditCardIcon,
  UserCircleIcon,
  UserPlusIcon,
  ArrowRightOnRectangleIcon,
  HomeIcon,
  HeartIcon,
  Cog8ToothIcon
} from '@heroicons/react/24/outline'
import axios from 'axios'


const universal_dashboard_navigation = [
  { name: 'My Profile', to: 'account', icon: Cog8ToothIcon },
]

const privileged_dashboard_navigation = [
  { name: 'Home', to: 'home', icon: HomeIcon },
  { name: 'Members', to: 'members', icon: UserCircleIcon },
  { name: 'Companions', to: 'companions', icon: UserPlusIcon },
  { name: 'Caretakers', to: 'users', icon: HeartIcon },
  { name: 'Billing', to: 'billing', icon: CreditCardIcon },
]





const DashboardPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()
  // const dashboard_route = location.pathname.slice(1).split('/')[1]
  const dashboard_route = location.pathname.split('/dashboard/')[1] || ''



  const logout = async () => {

    // Revoke authentication
    const delete_auth_res = await axios.delete(`/api/auth`)

    if (!delete_auth_res.data.success) {
      // TODO
      return
    }

    // Set session_token to UNSET
    await idb_set('session_token', "UNSET");

    // Reset global context
    global_update({
      ...global_context,
      authenticated: false,
      user_id: "UNSET",
      type: "UNSET"
    })

    // Trigger create access event doc
    await axios.post(`/api/events/access`, {
      user_id: global_context.user_id,
      event_metadata: {
        method: "web",
        action: "logout",
      }
    })

    // This doesn't appear to be necessary, but just having it here for safety and for code readability
    navigate("/login")
  }


  // Render - handle user view banner 
  useEffect(() => {

  }, [location])

  return (
    <div className="absolute w-full h-full flex flex-col justify-center items-center z-10">
      <div className="flex w-full min-h-full flex-1 flex-col justify-center">

        {/* Dashboard */}
        <div className="mx-0 max-w-7xl lg:flex h-full">

          {/* Left navigation */}
          {/* <div className="flex flex-col border-r py-4 px-4 bg-gray-50"> */}
          <div className="flex flex-col lg:fixed lg:top-0 lg:bottom-0 lg:left-0 border-r py-4 px-4 bg-gray-50">
            
            {/* Logo */}
            <div className="flex">
              <Link to="/" className="my-1.5 py-1.5">
                <ResponsiveLogo className="h-7" />
              </Link>
            </div>

            {/* Main nav */}
            <nav className="mt-4 flex lg:flex-col px-2 lg:px-1 py-4 lg:py-0 flex-1 justify-stretch overflow-x-auto">
              {/* Navigation  */}
              <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">

                {/* Privileged navigation */}
                {["owner", "admin"].includes(global_context.type)
                ? <>
                  {privileged_dashboard_navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={`/dashboard/${item.to}`}
                        className={classNames(
                          item.to === dashboard_route
                            ? 'bg-gray-50 text-blue-600'
                            : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.to === dashboard_route ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </>
                : <></>}

                {/* Universal navigation */}
                {universal_dashboard_navigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={`/dashboard/${item.to}`}
                      className={classNames(
                        item.to === dashboard_route
                          ? 'bg-gray-50 text-blue-600'
                          : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.to === dashboard_route ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}

              </ul>

              {/* Logout */}
              <div className="lg:fixed right-6 lg:left-4 lg:bottom-6 text-sm font-semibold w-fit cursor-pointer">
                <div
                  className="text-gray-700 hover:text-red-600 hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                  onClick={logout}
                >
                  <ArrowRightOnRectangleIcon className='text-gray-700 group-hover:text-red-600 h-6 w-6 shrink-0' />
                  <div className="">Logout</div>
                </div>
              </div>
            </nav>
          </div>

          {/* Main */}
          {/* <main className="w-full px-12"> */}
          <main className="lg:ml-60 w-full px-4 lg:px-12">
            <Outlet />
          </main>
        </div>


      </div>
    </div>
  )
}

export default DashboardPage