
import clsx from 'clsx'
import { useEffect } from 'react'





const OTP = ({
  otp,
  set_otp,
  otp_input_refs,
  submit_fn,
} : {
  otp : any
  set_otp : any
  otp_input_refs : any
  submit_fn : any
}) => {

  

  const handle_key_down = (e) => {
    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      const index = otp_input_refs.current.indexOf(e.target);
      if (index > 0) {
        set_otp((prevOtp) => [
          ...prevOtp.slice(0, index - 1),
          "",
          ...prevOtp.slice(index),
        ]);
        otp_input_refs.current[index - 1].focus();
      }
    }

    else if (e.key === 'Enter') {
      submit_fn();
    }
  };

  const handle_input = (e) => {
    const { target } = e;

    // If entering 6 characters at the exact same time (e.g. in case of iOS auto-fill), handle as if paste
    if (new RegExp(`^[0-9]{${otp.length}}$`).test(target.value)) {
      const digits = target.value.split("");
      set_otp(digits);
      return;
    }

    // Normal case
    const index = otp_input_refs.current.indexOf(target);
    if (target.value) {
      set_otp((prevOtp) => [
        ...prevOtp.slice(0, index),
        target.value,
        ...prevOtp.slice(index + 1),
      ]);
      if (index < otp.length - 1) {
        otp_input_refs.current[index + 1].focus();
      }
    }
  };

  const handle_focus = (e) => {
    e.target.select();
  };

  const handle_paste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    set_otp(digits);
  };

  // Renders
  useEffect(() => {

    otp_input_refs.current[0].focus()

  }, [])

  return (
    <div className="py-2">
      <div className="container">
        <div className="flex gap-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              // maxLength={1}
              value={digit}
              onChange={handle_input}
              onKeyDown={handle_key_down}
              onFocus={handle_focus}
              onPaste={handle_paste}
              ref={(el) => (otp_input_refs.current[index] = el)}
              // className="shadow-xs flex w-[64px] items-center justify-center rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
              className={clsx(
                'flex items-center justify-center text-center w-full rounded-lg border border-transparent shadow ring-1 ring-black/10 ',
                'px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])+3px)] text-base/6 sm:text-sm/6',
                'focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue',
              )}
            />
          ))}
          {/* You can conditionally render a submit button here based on otp length */}
        </div>
      </div>
    </div>
  );
}

export default OTP;
