import { get_member_property_value, get_companion_property_value, get_user_property_value, provision_type_map } from "./formats"
import { filter_regex, timestamp_to_local_datetime } from "./helpers"
import { member_property_options, companion_property_options, program_property_options, user_property_options } from "./options"




export const user_search_filter_fn = (user, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of user_property_options) {
      const user_value = get_user_property_value(user, property, true).toString()
      if (user_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}

export const user_filters_filter_fn = (user, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const user_value = get_user_property_value(user, property, true)
        if (!user_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const user_value = get_user_property_value(user, property, true)
        if (filter_value !== user_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let user_value = get_user_property_value(user, property, false)

        switch(typeof user_value) {
          case "string": {
            user_value = user_value
            // Always break
            break
          }
          case "boolean": {
            user_value = user_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== user_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const user_value = get_user_property_value(user, property, false)
        const user_value_date = new Date(user_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < user_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const user_value = get_user_property_value(user, property, false)
        const user_value_date = new Date(user_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > user_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value === filter_value
        if (user_value !== filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lte_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value <= filter_value
        if (user_value > filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gte_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value >= filter_value
        if (user_value < filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lt_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value < filter_value
        if (user_value >= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gt_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value > filter_value
        if (user_value <= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}




export const companion_search_filter_fn = (companion, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of companion_property_options) {
      const companion_value = get_companion_property_value(companion, property, true).toString()
      if (companion_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}

export const companion_filters_filter_fn = (companion, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const companion_value = get_companion_property_value(companion, property, true)
        if (!companion_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const companion_value = get_companion_property_value(companion, property, true)
        if (filter_value !== companion_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let companion_value = get_companion_property_value(companion, property, false)

        switch(typeof companion_value) {
          case "string": {
            companion_value = companion_value
            // Always break
            break
          }
          case "boolean": {
            companion_value = companion_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== companion_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const companion_value = get_companion_property_value(companion, property, false)
        const companion_value_date = new Date(companion_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < companion_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const companion_value = get_companion_property_value(companion, property, false)
        const companion_value_date = new Date(companion_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > companion_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_number": {
        const companion_value = get_companion_property_value(companion, property, false)
        const filter_value_number = Number(filter_value)
        
        // companion_value === filter_value
        if (companion_value !== filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lte_number": {
        const companion_value = get_companion_property_value(companion, property, false)
        const filter_value_number = Number(filter_value)
        
        // companion_value <= filter_value
        if (companion_value > filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gte_number": {
        const companion_value = get_companion_property_value(companion, property, false)
        const filter_value_number = Number(filter_value)
        
        // companion_value >= filter_value
        if (companion_value < filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lt_number": {
        const companion_value = get_companion_property_value(companion, property, false)
        const filter_value_number = Number(filter_value)
        
        // companion_value < filter_value
        if (companion_value >= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gt_number": {
        const companion_value = get_companion_property_value(companion, property, false)
        const filter_value_number = Number(filter_value)
        
        // companion_value > filter_value
        if (companion_value <= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}




export const member_search_filter_fn = (member, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of member_property_options) {
      const member_value = get_member_property_value(member, property, true).toString()
      if (member_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}

export const member_filters_filter_fn = (member, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const member_value = get_member_property_value(member, property, true)
        if (!member_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const member_value = get_member_property_value(member, property, true)
        if (filter_value !== member_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let member_value = get_member_property_value(member, property, false)

        switch(typeof member_value) {
          case "string": {
            member_value = member_value
            // Always break
            break
          }
          case "boolean": {
            member_value = member_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== member_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const member_value = get_member_property_value(member, property, false)
        const member_value_date = new Date(member_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < member_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const member_value = get_member_property_value(member, property, false)
        const member_value_date = new Date(member_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > member_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_number": {
        const member_value = get_member_property_value(member, property, false)
        const filter_value_number = Number(filter_value)

        // member_value === filter_value
        if (member_value !== filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lte_number": {
        const member_value = get_member_property_value(member, property, false)
        const filter_value_number = Number(filter_value)
        
        // member_value <= filter_value
        if (member_value > filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gte_number": {
        const member_value = get_member_property_value(member, property, false)
        const filter_value_number = Number(filter_value)
        
        // member_value >= filter_value
        if (member_value < filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lt_number": {
        const member_value = get_member_property_value(member, property, false)
        const filter_value_number = Number(filter_value)
        
        // member_value < filter_value
        if (member_value >= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gt_number": {
        const member_value = get_member_property_value(member, property, false)
        const filter_value_number = Number(filter_value)
        
        // member_value > filter_value
        if (member_value <= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}