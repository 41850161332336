import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'


import ReactLoading from "react-loading"


import { useNavigate } from "react-router-dom";
import { auth_axios } from '../../../libs/authWeb';
import { show_notification, classNames } from '../../../libs/helpers'









const DeactivateCompanionsModal = ({
  is_open,
  set_is_open,
  companion_ids,
  set_refresh_variable
} : {
  is_open : boolean
  set_is_open : any // todo
  companion_ids : any // todo
  set_refresh_variable : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // User input
  const [confirm_text, set_confirm_text] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [companions_are_deactivated, set_companions_are_deactivated] = useState(false)

  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")


  const check_deactivation_validity = async () => {
    
    // Set is_awaiting to true
    set_is_awaiting(true)

    // Set is_awaiting to false
    set_is_awaiting(false)
  }

  // Handle companion input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "confirm_text": {
        set_confirm_text(value)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate code
    if (!(companion_ids.length === 1 ? (confirm_text === "Deactivate companion") : (confirm_text === "Deactivate companions"))) {
      set_is_awaiting(false)

      // Show error message
      set_error_message(`Please type in "${companion_ids.length === 1 ? "Deactivate companion" : "Deactivate companions"}"`)

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update email
    const deactivate_companions_res = await auth_axios.patch(`/api/companions/deactivate`, {
      companion_ids: companion_ids,
    })

    if (!deactivate_companions_res.data.success) {
      switch (deactivate_companions_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/account page
          navigate(`/dashboard/companions`)
          
          // Always break
          break
        }
        case "CANNOT_DEACTIVATE_INACTIVE_COMPANIONS": {
          set_is_awaiting(false)
          set_error_message("You cannot deactivate inactive companion(s)")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (deactivate_companions_res.data.status) {
      case "SUCCESS": {
        set_is_awaiting(false)
        set_companions_are_deactivated(true)
        set_success_message(`${companion_ids.length === 1 ? "Companion was" : "Companions were"} successfully deactivated`)

        // Show success notification
        show_notification(protected_context, protected_update, "success", "Success", `${companion_ids.length === 1 ? "Companion was" : "Companions were"} successfully deactivated`)
        
        // Re-render /dashboard/companions
        set_refresh_variable(new Date())

        // Redirect to dashboard/account page
        navigate(`/dashboard/companions`)

        // Always break
        break
      }
      case "_____": {

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders  
  useEffect(() => {

  }, [])

  // Renders  
  useEffect(() => {

    // Check deactivation validity every time the modal opens
    if (is_open) {
      check_deactivation_validity()
    }

  }, [is_open])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                 
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-red-500">
                        {companion_ids.length === 1 ? "Deactivate companion" : "Deactivate companions"}
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        This action will have permanent effects
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 flow-root">

                    
                    
                    <dl className="mt-4 pt-4 space-y-6 border-t border-gray-200 text-sm">




                      {/* Warning */}
                      <div className="pt-6 font-medium text-sm text-gray-900">
                        {companion_ids.length === 1 ? "Deactivating the companion will cease all communications to and from the companion immediately. Billing will be paused and prorated credits will be applied to the account within the next 24 hours _________. Companion may be reactivated later." : "Deactivating the companions will cease all communications to and from each companion immediately. Billing will be paused and prorated credits will be applied to the account within the next 24 hours _________. Companions may be reactivated later."}
                      </div>


                      {/* Confirm text */}
                      <div className="sm:flex items-center">
                        <dt className="font-medium text-gray-900 pr-6">To deactivate {companion_ids.length === 1 ? "this companion" : "these companions"}, please type "{companion_ids.length === 1 ? "Deactivate companion" : "Deactivate companions"}" </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto pr-6">
                          <input
                            type="text"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-red-500 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                            value={confirm_text}
                            placeholder={companion_ids.length === 1 ? "Deactivate companion" : "Deactivate companions"}
                            onChange={(e) => handle_user_input("confirm_text", e.target.value)}
                            disabled={is_awaiting || companions_are_deactivated}
                          />
                        </dd>
                        <button 
                          className={classNames(
                            is_awaiting || companions_are_deactivated || !(companion_ids.length === 1 ? (confirm_text === "Deactivate companion") : (confirm_text === "Deactivate companions")) ? 'text-white bg-red-300' : 'text-white bg-red-600 hover:bg-red-500',
                            'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                          )}
                          onClick={submit}
                          disabled={is_awaiting || companions_are_deactivated || !(companion_ids.length === 1 ? (confirm_text === "Deactivate companion") : (confirm_text === "Deactivate companions"))}
                        >
                          {!is_awaiting
                          ? <span>Delete</span>
                          :<ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />}
                        </button> 
                      </div>

                      {/* Error message */}
                      {error_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <div>
                            <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                          </div>
                          <div className="text-sm font-medium text-red-400">{error_message}</div>
                        </div>
                      : <></>}

                      {/* Success message */}
                      {companions_are_deactivated && success_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <div>
                            <CheckCircleIcon className="pt-[3px] w-4 h-4 text-green-600 h-full"/>
                          </div>
                          <div className="text-sm font-medium text-green-600">{success_message}</div>
                        </div>
                      : <></>}

                    </dl>
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DeactivateCompanionsModal;
