
import React, { useEffect, useState, Fragment } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../../contexts/ProtectedContext'

import { Link, useLocation, useNavigate } from "react-router-dom"
import { Tab, Transition, Listbox } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ArrowLongLeftIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'


import { mixpanel_client_track } from '../../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../../libs/authWeb'
import { get_duplicates_in_array, show_notification, classNames } from '../../../../../../libs/helpers'
import { validate_email_address } from '../../../../../../libs/validate'
import { authentication_sso_method_options, authentication_type_options } from '../../../../../../libs/options'
import { authentication_sso_method_map, authentication_type_map } from '../../../../../../libs/formats'




const UsersAddInviteEmailsPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Org data
  const [account_data, set_account_data] = useState({})
  const [account_data_and_users_are_fetched, set_account_data_and_users_are_fetched] = useState(false)
  const [users, set_users] = useState([])

  // User input
  const [email_addresses_text, set_email_addresses_text] = useState("")

  const [authentication_type, set_authentication_type] = useState("password")
  const [sso_method, set_sso_method] = useState("")

  const [is_preview, set_is_preview] = useState(false)
  const [preview_list, set_preview_list] = useState([])

  // Error message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_added, set_is_added] = useState(false)



  const get_account_data_and_users = async () => {
    // Set is_fetched to false
    set_account_data_and_users_are_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/accounts`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get user data
    const get_org_users_res = await auth_axios.get(`/api/users`)

    if (!get_org_users_res.data.success) {
      switch (get_org_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_account_data(get_org_res.data.account_data)
    set_users(get_org_users_res.data.users)
    set_account_data_and_users_are_fetched(true)
  }


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "email_addresses_text": {
        set_email_addresses_text(value)

        // Always break
        break
      }
      case "authentication_type": {
        set_authentication_type(value)

        // Set and unset default SSO method
        if (value === "sso") {
          set_sso_method("google_oauth")
        }
        else {
          set_sso_method("")
        }

        // Always break
        break
      }
      case "sso_method": {
        set_sso_method(value)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const generate_preview = () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!account_data_and_users_are_fetched) {
      set_is_awaiting(false)
      return
    }

    // Separate by lines and commas
    const email_addresses = email_addresses_text.split(`\n`).map(line => line.split(',')).flat().map(email_address => email_address.trim()).filter(email_address => email_address)

    let is_invalid = false
    let error_message_draft = ""

    // At least one email
    if (email_addresses.length < 1) {
      error_message_draft += `At least one email address must be provided.\n`
      is_invalid = true
    }

    for (const email_address of email_addresses) {
      // Validate email address
      if (!validate_email_address(email_address)) {
        error_message_draft += `${email_address} is not a valid email.\n`
        is_invalid = true
      }

      // Make sure email address and domain match
      if (email_address.split("@")[1] !== account_data["account_domain"] &&  !account_data["account_metadata"]["additional_account_domains"].includes(email_address.split("@")[1])) {
        error_message_draft += `${email_address} does not match your account domain (${account_data["account_domain"]}).\n`
        is_invalid = true
      } 
      
    }

    // Make sure every email address is unique
    const duplicate_email_addresses = get_duplicates_in_array(email_addresses)
    if (duplicate_email_addresses.length !== 0) {
      for (const duplicate_email_address of duplicate_email_addresses) {
        error_message_draft += `${duplicate_email_address} is found more than once.\n`
      }
      is_invalid = true
    }

    // Make sure user with an email does not already exist
    const users_email_addresses_set = new Set(users.map(user => user.email_address))
    const existing_email_addresses = [... new Set(email_addresses)].filter(email_address => users_email_addresses_set.has(email_address))
    if (existing_email_addresses.length !== 0) {
      for (const existing_email_address of existing_email_addresses) {
        error_message_draft += `${existing_email_address} is already in your account.\n`
      }
      is_invalid = true
    }

    /////
    // If invalid, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK


    // Generate preview list
    set_preview_list(email_addresses)
    set_is_preview(true)

    // Toggle awaiting
    set_is_awaiting(false)
  }



  const submit = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!account_data_and_users_are_fetched) {
      set_is_awaiting(false)
      return
    }

    // Separate by lines and commas
    const email_addresses = email_addresses_text.split(`\n`).map(line => line.split(',')).flat().map(email_address => email_address.trim()).filter(email_address => email_address)

    let is_invalid = false
    let error_message_draft = ""

    // At least one email
    if (email_addresses.length < 1) {
      error_message_draft += `At least one email address must be provided.\n`
      is_invalid = true
    }

    for (const email_address of email_addresses) {
      // Validate email address
      if (!validate_email_address(email_address)) {
        error_message_draft += `${email_address} is not a valid email.\n`
        is_invalid = true
      }

      // Make sure email address and domain match
      if (email_address.split("@")[1] !== account_data["account_domain"] &&  !account_data["account_metadata"]["additional_account_domains"].includes(email_address.split("@")[1])) {
        error_message_draft += `${email_address} does not match your account domain (${account_data["account_domain"]}).\n`
        is_invalid = true
      } 
    }

    // Make sure every email address is unique
    const duplicate_email_addresses = get_duplicates_in_array(email_addresses)
    if (duplicate_email_addresses.length !== 0) {
      for (const duplicate_email_address of duplicate_email_addresses) {
        error_message_draft += `${duplicate_email_address} is found more than once.\n`
      }
      is_invalid = true
    }

    // Make sure user with an email does not already exist
    const users_email_addresses_set = new Set(users.map(user => user.email_address))
    const existing_email_addresses = [... new Set(email_addresses)].filter(email_address => users_email_addresses_set.has(email_address))
    if (existing_email_addresses.length !== 0) {
      for (const existing_email_address of existing_email_addresses) {
        error_message_draft += `${existing_email_address} is already in your account.\n`
      }
      is_invalid = true
    }

    /////
    // If invalid, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK



    // Execute add users
    const post_org_users_res = await auth_axios.post(`/api/users/invite`, {
      email_addresses: email_addresses,
      authentication_type: authentication_type,
      sso_method: sso_method
    })

    if (!post_org_users_res.data.success) {
      switch (post_org_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/users page
          navigate(`/dashboard/users`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // React to response
    switch (post_org_users_res.data.status) {
      case "SUCCESS": {
        // Set success message and disable edit
        set_success_message("User(s) were successfully added and invitations have been sent")
        set_is_added(true)

        // Toggle
        set_is_awaiting(false)

        // Show success message
        // alert("Users were successfully added")
        show_notification(protected_context, protected_update, "success", "Success", "User(s) were successfully added and invitations have been sent")

        // // If adding users made the plan inactive, show and alert warning message
        // if (post_org_users_res.data.account_status === "inactive") {
        //   set_error_message("The number of users in your account surpasses the number of seats in your plan, and your plan is inactive. Any existing campaign will continue to operate, but you will not be able to create new campaigns until your plan is active. Please visit the Billing tab to increase the number of seats.")

        //   alert("Users were successfully added and your plan is inactive. Please add more seats to your plan.")
        // }
        // else {
        //   // Alert (in case moved away from the page)
        //   alert("Users were successfully added")
        // }

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        set_error_message(post_org_users_res.data.error_message)
        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }



  // Renders  
  useEffect(() => {

    // Get org data
    get_account_data_and_users()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_users_add_invite_emails_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">

      {/* Descriptions */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Link to={`/dashboard/users/add`} className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>
          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Invite users</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
          Paste email addresses of users to add, each separated by comma or line 
          </p>
        </div>
      </div>

      <div className="mt-8">
        {/* Authentication type & SSO method */}
        <Listbox value={authentication_type} onChange={(e) => handle_user_input("authentication_type", e)}>
          {({ open }) => (
            <div className="flex items-center space-x-4 mt-4">
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Authentication type</Listbox.Label>
              <div className="relative w-48">
                <Listbox.Button 
                  className="relative w-full cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{authentication_type_map[authentication_type]}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {authentication_type_options.filter(authentication_type_option => authentication_type_option !== "disabled").map((authentication_type_option) => (
                      <Listbox.Option
                        key={authentication_type_option}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={authentication_type_option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              { authentication_type_map[authentication_type_option] }
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>

        {/* Authentication type & SSO method */}
        {authentication_type === "sso"
        ? <Listbox value={sso_method} onChange={(e) => handle_user_input("sso_method", e)}>
          {({ open }) => (
            <div className="flex items-center space-x-4 mt-4">
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">SSO method</Listbox.Label>
              <div className="relative w-48">
                <Listbox.Button 
                  className="relative w-full cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{authentication_sso_method_map[sso_method]}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {authentication_sso_method_options.map((sso_method_option) => (
                      <Listbox.Option
                        key={sso_method_option}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={sso_method_option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              { authentication_sso_method_map[sso_method_option] }
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
        : <></>}
      </div>

      
      

      {/* Emails */}
      <div className="mt-8">
        <Tab.Group onChange={(index) => { if (index === 1) generate_preview(); else set_is_preview(false); }} selectedIndex={is_preview ? 1 : 0}>
          <Tab.List className="flex items-center">
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                  'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                )
              }
            >
              Emails
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                  'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                )
              }
            >
              {account_data_and_users_are_fetched
              ? "Preview"
              : <ReactLoading
                type='spokes'
                color='#343D46'
                height={20}
                width={20}
              />}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
              <div>
                <textarea
                  className={classNames(is_awaiting || is_added ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                  placeholder="john@example.com, jane@example.com"
                  rows={16}
                  value={email_addresses_text}
                  onChange={(e) => handle_user_input("email_addresses_text", e.target.value)}
                  disabled={is_awaiting || is_added}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
              <div className="border-b">
                <div className="mx-px mt-px pt-2 pb-2 text-sm leading-5 text-gray-800">
                  <p className="my-2 text-sm font-semibold text-gray-700">{`${preview_list.length} users ${is_added ? "added" : "to add"}`}</p>
                  <ul role="list" className="divide-y divide-gray-100 h-[500px] overflow-y-scroll">
                    {preview_list.map((email_address) => (
                      <li key={email_address} className="flex gap-x-4 py-5">
                        <CheckCircleIcon
                          className={classNames(is_added ? "text-green-600" : "text-blue-600", "h-5 w-5 ")}
                          aria-hidden="true"
                        />
                        <div className="min-w-0 flex space-x-2">
                          <p className="text-sm font-semibold text-gray-700">{email_address}</p>
                          <p className={classNames(is_added ? "text-green-600" : "text-blue-600", "text-sm font-semibold")}>{is_added ? "Added" : "Validated"}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        {/* Error message */}
        {error_message
        ? <div className="mt-2 flex space-x-2 items-start">
            <div>
              <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
            </div>
            {/* Multi-line error message */}
            <div className="text-sm font-medium text-red-400">
              {error_message.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        : <></>}

        {/* Success message */}
        {is_added && success_message
        ? <div className="mt-6 flex space-x-2 items-start">
            <div>
              <CheckCircleIcon className="pt-[3px] w-4 h-4 text-green-600 h-full"/>
            </div>
            <div className="text-sm font-medium text-green-600">{success_message}</div>
          </div>
        : <></>}

        {/* Submit button */}
        <div className="mt-2 flex justify-end">
          <button
            type="submit"
            onClick={submit}
            className={classNames(is_awaiting || is_added ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
            disabled={is_awaiting || is_added}
          >
            {is_awaiting ? (
            <ReactLoading
              type='spokes'
              color='#ffffff'
              height={20}
              width={20}
            />
          ) : (
            <span>Add users & send invitations</span>
          )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default UsersAddInviteEmailsPage