
import { useEffect, useState, Fragment } from 'react'


import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'


import ReactLoading from "react-loading"

import { useNavigate, Link } from "react-router-dom"

import { Transition, Listbox } from '@headlessui/react'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/react/24/outline'



import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { filter_type_display_map, get_companion_property_filters, get_companion_property_value, companion_property_map } from '../../../libs/formats';
import { companion_filters_filter_fn, companion_search_filter_fn } from '../../../libs/searchFilterFunctions'
import { classNames, filter_regex, show_notification } from '../../../libs/helpers'
import { companion_property_options } from '../../../libs/options'

import AddFilterModal from '../../../components/AddFilterModal'
import DeactivateCompanionsModal from './DeactivateCompanionsModal'
import ReactivateCompanionsModal from './ReactivateCompanionsModal'
import DeleteCompanionsModal from './DeleteCompanionsModal'







const Companion = ({
  companion,
  companion_properties,
  companion_checked_map,
  set_companion_checked_map,
} : {
  companion : any
  companion_properties : any
  companion_checked_map : any
  set_companion_checked_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(companion_checked_map[companion.companion_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={() => navigate(`/dashboard/companions/${companion.companion_id}`)}
    >
      {/* Checkbox */}
      <td className="relative px-7 sm:w-12 sm:px-6">
        {companion_checked_map[companion.companion_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={companion_checked_map[companion.companion_id]}
          onChange={(e) => 
            set_companion_checked_map({
            ...companion_checked_map,
            [companion.companion_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>

      {/* Companion properties */}
      {companion_properties.map((property, index) => <td
        key={index}
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm',
          index === 0 ? (companion_checked_map[companion.companion_id] ? 'text-blue-600 font-medium' : 'text-gray-900 font-medium') : "text-gray-500"
        )}
      >
        {get_companion_property_value(companion, property, true)}
      </td>)}
    </tr>
  )
}

const Filter = ({
  property,
  filters,
  set_filters,
  set_current_filter
} : {
  property : any
  filters : any
  set_filters : any
  set_current_filter : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // States
  const [filter_type, set_filter_type] = useState(Object.keys(get_companion_property_filters(property))[0])
  const [filter_value, set_filter_value] = useState("")




  // Handle companion input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "filter_type": {
        set_filter_type(value)

        set_filter_value("")
  
        // Always break
        break
      }
      case "filter_value": {
        set_filter_value(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    // set_error_message("")
  }


  const submit_filter_value = () => {
    set_filters([...filters, `{{${property}}}:{{${filter_type}}}:{{${filter_value}}}`])
    set_filter_value("")
    set_current_filter("")
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <div className="absolute top-full left-4 w-72 bg-white px-4 py-4 rounded-md font-normal shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 space-y-4">
      
      {/* Filter type */}
      <div className="font-normal flex items-center space-x-2">
        <div>{`${companion_property_map[property]} `}</div>
        <Listbox value={filter_type} onChange={(e) => handle_user_input("filter_type", e)}>
          {({ open }) => (
            <>
              <div className="relative w-1/2">
                <Listbox.Button 
                  className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{filter_type_display_map[filter_type]}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {Object.keys(get_companion_property_filters(property)).map((filter_option) => (
                      <Listbox.Option
                        key={filter_option}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={filter_option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {filter_type_display_map[filter_option]}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <div>{`:`}</div>
      </div>

      {/* Filter value */}
      {(() => {
        switch(filter_type) {
          case "includes_text": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                placeholder="Text"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_text": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                placeholder="Text"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_option": {
            return <Listbox value={filter_value} onChange={(e) => handle_user_input("filter_value", e)}>
              {({ open }) => (
                <>
                  <div className="relative w-1/2">
                    <Listbox.Button 
                      className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span className="block truncate">{filter_value === "" ? "Select" : get_companion_property_filters(property)[filter_type].format(filter_value)}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>
    
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {get_companion_property_filters(property)[filter_type].options.map((option) => (
                          <Listbox.Option
                            key={option}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {get_companion_property_filters(property)[filter_type].format(option)}
                                </span>
    
                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          }
          case "before_date": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "after_date": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_number": {
            return <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "lt_number": {
            return <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "gt_number": {
            return <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "lte_number": {
            return <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "gte_number": {
            return <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          default: {
            return <></>
          }
        }
      })()}

      {/* Submit */}
      <div className="flex justify-end">
        <button
          onClick={submit_filter_value}
          className={classNames(filter_value === "" ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
          disabled={filter_value === ""}
        >
          Add filter
        </button>
      </div>
    </div>
  )
}


const CompanionsPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()
  
  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // States
  const [companions, set_companions] = useState([])
  
  const [active_companion_checked_map, set_active_companion_checked_map] = useState({})
  const [inactive_companion_checked_map, set_inactive_companion_checked_map] = useState({})

  const [companions_and_org_data_are_fetched, set_companions_and_org_data_are_fetched] = useState(false)

  // Current
  const [current_tab, set_current_tab] = useState("active")
  const [current_filter, set_current_filter] = useState("")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Search & filters
  const [search_text, set_search_text] = useState("")
  const [filters, set_filters] = useState([])

  // Modal
  const [add_filter_modal_is_open, set_add_filter_modal_is_open] = useState(false)
  const [deactivate_companions_modal_is_open, set_deactivate_companions_modal_is_open] = useState(false)
  const [reactivate_companions_modal_is_open, set_reactivate_companions_modal_is_open] = useState(false)
  const [delete_companions_modal_is_open, set_delete_companions_modal_is_open] = useState(false)


  // Handle companion input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_companions_and_org_data = async () => {
    // Set is_fetched to false
    set_companions_and_org_data_are_fetched(false)

    const test = await auth_axios.get(`/api/companions/test`)

    return

    // Execute get companion data
    const get_org_companions_res = await auth_axios.get(`/api/companions`)

    if (!get_org_companions_res.data.success) {
      switch (get_org_companions_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get org data
    const get_org_res = await auth_axios.get(`/api/accounts`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_companions(get_org_companions_res.data.companions)
    set_active_companion_checked_map(get_org_companions_res.data.companions.filter(companion => companion.active).reduce((acc, companion) => ({...acc, [companion.companion_id]: false}), {}))
    set_inactive_companion_checked_map(get_org_companions_res.data.companions.filter(companion => !companion.active).reduce((acc, companion) => ({...acc, [companion.companion_id]: false}), {}))

    // set_companion_properties(get_org_res.data.account_data.account_metadata.companion_properties)
    set_companions_and_org_data_are_fetched(true)
  }
  
  const toggle_active_companions = (e) => {
    const filtered_companion_ids_set = new Set(companions
      // Filter by search
      .filter(companion => companion_search_filter_fn(companion, search_text))
      // Filter by filters
      .filter(companion => companion_filters_filter_fn(companion, filters))

      // Filter by active
      .filter(companion => companion.active)
      // Map by companion_id
      .map(companion => companion.companion_id)
    ) 
    set_active_companion_checked_map(Object.keys(active_companion_checked_map).reduce((acc, companion_id) => ({ ...acc, [companion_id]: filtered_companion_ids_set.has(companion_id) ? e.target.checked : active_companion_checked_map[companion_id] }), {}))
  }

  const toggle_inactive_companions = (e) => {
    const filtered_companion_ids_set = new Set(companions
      // Filter by search
      .filter(companion => companion_search_filter_fn(companion, search_text))
      // Filter by filters
      .filter(companion => companion_filters_filter_fn(companion, filters))
      
      // Filter by active
      .filter(companion => !companion.active)
      // Map by companion_id
      .map(companion => companion.companion_id)
    )
    set_inactive_companion_checked_map(Object.keys(inactive_companion_checked_map).reduce((acc, companion_id) => ({ ...acc, [companion_id]: filtered_companion_ids_set.has(companion_id) ? e.target.checked : inactive_companion_checked_map[companion_id] }), {}))
  }

  const uncheck_all_active_companions = () => {
    set_active_companion_checked_map(Object.keys(active_companion_checked_map).reduce((acc, companion_id) => ({ ...acc, [companion_id]: false }), {}))
  }
  
  const uncheck_all_inactive_companions = () => {
    set_inactive_companion_checked_map(Object.keys(inactive_companion_checked_map).reduce((acc, companion_id) => ({ ...acc, [companion_id]: false }), {}))
  }

  // Renders  
  // render upon load & upon refresh_variable change
  useEffect(() => {

    get_companions_and_org_data()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_companions_visited", global_context.user_id)

  }, [refresh_variable])


  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Companions</h1>
          <p className="mt-2 text-sm text-gray-700">
            Companions in your account
          </p>
        </div>
        <Link to="add" className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Add companions
          </button>
        </Link>
      </div>

      {/* Data */}
      <div className="mt-2 flow-root">


        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Active tab */}
              <div
                className={classNames(
                  current_tab === "active"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_inactive_companions(); set_current_tab("active") }}
              >
                Companions
                <span
                  className={classNames(
                    current_tab === "active" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {companions.filter(companion => companion.active).length}
                </span>
              </div>

              {/* Inactive tab */}
              <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_companions(); set_current_tab("inactive") }}
              >
                Deactivated companions
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {companions.filter(companion => !companion.active).length}
                </span>
              </div>
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">

          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">

            {/* Left side - Number selected and bulk action buttons */}
            <div>
              {Object.values(active_companion_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(active_companion_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_deactivate_companions_modal_is_open(true)}
                >
                  Deactivate
                </button>
                {/* <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={create_campaign_with_selected_companions}
                >
                  {Object.values(active_companion_checked_map).filter(checked => checked).length === 1 ? "Create campaign with selected companion" : "Create campaign with selected companions"}
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={export_selected_companions_to_csv}
                >
                  {Object.values(active_companion_checked_map).filter(checked => checked).length === 1 ? "Export selected to CSV" : "Export selected to CSV"}
                </button> */}
              </div>
              : <></>}
              {Object.values(inactive_companion_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(inactive_companion_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-blue-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_reactivate_companions_modal_is_open(true)}
                >
                  Reactivate
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_delete_companions_modal_is_open(true)}
                >
                  Delete
                </button>
              </div>
              : <></>}
            </div>

            {/* Right side */}
            <div className="flex space-x-4 items-center">
              {/* Add a filter button */}
              <button
                type="button"
                className="inline-flex items-center rounded-3xl bg-white px-4 py-1 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white h-8 w-max"
                onClick={() => set_add_filter_modal_is_open(true)}
              >
                <span className="hidden sm:block">Add a filter</span>
                <PlusIcon className="sm:ml-1 h-4 w-4" />
              </button>

              {/* Search field */}
              <div className="w-[130px] sm:w-64">
                <div className="relative rounded-md shadow-sm">
                  <div
                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    aria-hidden="true"
                  >
                    <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    placeholder="Search"
                    value={search_text}
                    onChange={(e) => handle_user_input("search_text", e.target.value)}
                  />
                </div>
              </div>
            </div>
            
          </div>

          {/* Bottom row (hidden) - active filters */}
          {filters.length > 0
          ? <div className="bg-gray-50 rounded-md">
            <div className="mx-auto max-w-7xl px-4 py-2 my-2 sm:flex sm:items-center sm:px-6 lg:px-8">
              <h3 className="text-sm font-medium text-gray-500">
                Filters
                <span className="sr-only">, active</span>
              </h3>

              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

              <div className="mt-2 sm:ml-4 sm:mt-0">
                <div className="-m-1 flex flex-wrap items-center">
                  {filters.map((filter) => (
                    <span
                      key={filter}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{`'${companion_property_map[filter.match(filter_regex)[1]]}' ${filter_type_display_map[filter.match(filter_regex)[2]]} '${filter.match(filter_regex)[3]}'`}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={() => set_filters(filters.filter(elm => elm !== filter))}
                      >
                        <span className="sr-only">Remove filter for {filter}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          : <></>}

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block align-middle h-[500px] w-[calc(100vw-100px)] sm:w-[calc(100vw-500px)]">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr className="">
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        {/* Toggle all */}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={(() => {
                            
                            const filtered_companions = companions
                              // Filter by search
                              .filter(companion => companion_search_filter_fn(companion, search_text))
                              // Filter by filters
                              .filter(companion => companion_filters_filter_fn(companion, filters))

                            switch(current_tab) {
                              case "active": {
                                return filtered_companions.filter(companion => companion.active).length > 0 && filtered_companions
                                  // Filter by active
                                  .filter(companion => companion.active)
                                  // Map by is_checked
                                  .map(companion => active_companion_checked_map[companion.companion_id])
                                  // Check if every is true
                                  .every(is_checked => is_checked)

                                // return Object.values(active_companion_checked_map).length > 0 && Object.values(active_companion_checked_map).every(is_checked => is_checked)      
                              }
                              case "inactive": {
                                return filtered_companions.filter(companion => !companion.active).length > 0 && filtered_companions
                                  // Filter by active
                                  .filter(companion => !companion.active)
                                  // Map by is_checked
                                  .map(companion => inactive_companion_checked_map[companion.companion_id])
                                  // Check if every is true
                                  .every(is_checked => is_checked)

                                // return Object.values(inactive_companion_checked_map).length > 0 && Object.values(inactive_companion_checked_map).every(is_checked => is_checked)
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                          onChange={(() => {
                            switch(current_tab) {
                              case "active": {
                                return toggle_active_companions
                              }
                              case "inactive": {
                                return toggle_inactive_companions
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                        />
                      </th>
                      
                      {/* Columns */}
                      {companion_property_options.map((property, index) => <th 
                        key={index}
                        scope="col" 
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        <div className="flex items-center space-x-2">
                          <div className="">{companion_property_map[property]}</div>
                          <span className="flex relative items-center">
                            <ChevronDownIcon 
                              className="h-[16px] w-[16px] min-h-[16px] min-w-[16px] cursor-pointer" 
                              onClick={current_filter === property ? () => set_current_filter("") : () => set_current_filter(property)}
                            />
                          </span>
                        </div>
                        {current_filter === property
                        ? <Filter 
                          property={property}
                          filters={filters}
                          set_filters={set_filters}
                          set_current_filter={set_current_filter}
                        />
                        : <></>}
                      </th>)}
                      
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {companions_and_org_data_are_fetched
                    ? companions.filter((() => {
                      switch(current_tab) {
                        case "active": {
                          return companion => companion.active
                        }
                        case "inactive": {
                          return companion => !companion.active
                        }
                        default: {
                          return
                        }
                      }
                    })())
                    // Filter by search
                    .filter(companion => companion_search_filter_fn(companion, search_text))
                    // Filter by filters
                    .filter(companion => companion_filters_filter_fn(companion, filters))
                    // Map companions
                    .map((companion) => (
                      <Companion 
                        key={companion.companion_id} 
                        companion={companion} 
                        companion_properties={companion_property_options}
                        companion_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return active_companion_checked_map
                            }
                            case "inactive": {
                              return inactive_companion_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                        set_companion_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return set_active_companion_checked_map
                            }
                            case "inactive": {
                              return set_inactive_companion_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
      </div>



      {/* Modals */}
      <AddFilterModal
        is_open={add_filter_modal_is_open}
        set_is_open={set_add_filter_modal_is_open}
        filters={filters}
        set_filters={set_filters}
        property_options={companion_property_options}
        property_map={companion_property_map}
        get_property_filters={get_companion_property_filters}
      />
      <DeactivateCompanionsModal 
        is_open={deactivate_companions_modal_is_open}
        set_is_open={set_deactivate_companions_modal_is_open}
        companion_ids={Object.keys(active_companion_checked_map).filter(companion_id => active_companion_checked_map[companion_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <ReactivateCompanionsModal 
        is_open={reactivate_companions_modal_is_open}
        set_is_open={set_reactivate_companions_modal_is_open}
        companion_ids={Object.keys(inactive_companion_checked_map).filter(companion_id => inactive_companion_checked_map[companion_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteCompanionsModal 
        is_open={delete_companions_modal_is_open}
        set_is_open={set_delete_companions_modal_is_open}
        companion_ids={Object.keys(inactive_companion_checked_map).filter(companion_id => inactive_companion_checked_map[companion_id])}
        set_refresh_variable={set_refresh_variable}
      />
    </div>
  )
}

export default CompanionsPage