const esp_domains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "msn.com",
  "comcast.com",
  "icloud.com",
  "protonmail.com",
  "proton.me",
  "sky.com",
  "live.com",
  "verizon.net",
  "gmx.com",
  "att.net",
  "zohomail.com",
  "mail.com",
  "outlook.com",
]



export const validate_email_address = (email_address: string) => { 
  return /^[a-z0-9._%+-]+@(?:[a-z0-9-]+\.)+[a-z]{2,}$/.test(email_address)
}

export const validate_password = (password: string) => {
  return /^(?=.*[0-9])(?=.*[\W_])[a-zA-Z0-9\W_]{8,}$/.test(password)
}


export const validate_url = (url: string) => {
  return /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,6})(\/[^\s]*)?$/.test(url)
}


export const validate_phone_number = (phone_number: string) => {
  // return /^\+?[1-9]\d{1,14}$/.test(phone_number)
  return /^\+1[2-9]\d{9}$/.test(phone_number)
}

export const validate_area_code = (area_code: string) => {
  return /^\d{3}$/.test(area_code)
}

export const validate_postal_code = (postal_code: string) => {
  return /^[1-9]\d{4}$/.test(postal_code)
}

export const validate_age = (age: string) => {
  return /^[1-9]\d*$/.test(age) && Number(age) <= 150
}

export const validate_birthday = (birthday: string) => {
  // Check if the input is an 8-character numeric string
  if (!/^\d{8}$/.test(birthday)) {
    return false;
  }

  const year = parseInt(birthday.slice(0, 4), 10);
  const month = parseInt(birthday.slice(4, 6), 10);
  const day = parseInt(birthday.slice(6, 8), 10);

  // Validate that the year is after 1900 and less than or equal to the current year
  const currentYear = new Date().getFullYear();
  if (year < 1900 || year > currentYear) {
    return false;
  }

  // Validate the month (1-12)
  if (month < 1 || month > 12) {
    return false;
  }

  // Validate the day (1-31) and check if the date is valid
  const date = new Date(year, month - 1, day); // JavaScript months are 0-indexed
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};