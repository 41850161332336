
import { useEffect, useState } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import { useLocation, Link } from "react-router-dom"


import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';







const HomePage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // User data
  const [account_data, set_account_data] = useState({})
  const [account_data_is_fetched, set_account_data_is_fetched] = useState(false)

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Modal
  const [google_maps_modal_is_open, set_google_maps_modal_is_open] = useState(false)





  const get_account_data = async () => {

    // await axios.put('/api/test/four')


    // Set is_fetched to false
    set_account_data_is_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/accounts`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_account_data(get_org_res.data.account_data)
    set_account_data_is_fetched(true)
  }


  

  // Renders 
  useEffect(() => {

    get_account_data()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_account_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Home</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Your Aldermate
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

            {/* Finish onboarding name */}
            {account_data_is_fetched && !account_data["onboarding_is_complete"] ? (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Onboarding status</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    <span>Incomplete</span>
                  </div>
                  <Link 
                    to="#"
                    // to="/onboarding" 
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Finish onboarding
                  </Link>
                </dd>
              </div>
            ) : (
              <></>
            )}

            {/* Account name */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account name</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {account_data_is_fetched ? (
                    <span>{account_data["account_metadata"]["name"]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* Only available to owner */}
                {["owner"].includes(global_context.type)
                ? <Link to="update/name" className="font-semibold text-blue-600 hover:text-blue-500">
                    Update
                  </Link>
                : <></>}
              </dd>
            </div>




            {/* Delete account */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-red-400">Delete account</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">To close your subscription, please reach out to support@aldermate.com</div>
              </dd>
            </div>
          </dl>
        </div>

      </div>

      {/* Modals */}
      {/* <GoogleMapsModal 
        is_open={google_maps_modal_is_open}
        set_is_open={set_google_maps_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      /> */}


    </div>
  )
}

export default HomePage