
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom"


import axios from "axios"

import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'

import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

import { HASH } from '../../libs/crypto'
import { string_to_uint8_array, uint8_array_to_base64 } from '../../libs/converters'

import { handle_enter_keydown } from '../../libs/helpers'
import { validate_email_address, validate_password } from '../../libs/validate'
import { ResponsiveLogoMarkOnly } from '../../components/logo'


import { mixpanel_client_track } from '../../libs/mixpanelClient'



const ResetPage = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Awaiting
  const [is_awaiting, set_is_awaiting] = useState(false)


  // SEND PASSWORD RESET EMAIL

  // User input
  const [email_address, set_email_address] = useState("")
  // Autofocus on email address field
  const email_address_ref = useRef<any>(null);

  // Statuses
  const [password_reset_email_is_sent, set_password_reset_email_is_sent] = useState(false)


  // RESET PASSWORD

  // Search params
  const [search_params, set_search_params] = useSearchParams()
  const email_validation_token_id = search_params.get("email_validation_token_id")
  const code = search_params.get("code")

  // User input
  const [password, set_password] = useState("")
  const [password_confirm, set_password_confirm] = useState("")
  
  const password_ref = useRef<any>(null);

  // Others
  const [user_id, set_user_id] = useState("")
  const [password_reset_token_id, set_password_reset_token_id] = useState("")

  // Statuses
  const [email_validation_token_is_validated, set_email_validation_token_is_validated] = useState(false)
  const [password_is_reset, set_password_is_reset] = useState(false)

  

  


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "password": {
        set_password(value)
  
        // Always break
        break
      }
      case "password_confirm": {
        set_password_confirm(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  // Send password reset email function
  const send_password_reset_email = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Invalid email address")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK



    // Execute signup
    const post_auth_password_reset_email_res = await axios.post(`/api/auth/password-reset-email/send`, {
      email_address: email_address,
    })

    if (!post_auth_password_reset_email_res.data.success) {
      switch (post_auth_password_reset_email_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to login page
          navigate(`/login`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_password_reset_email_res.data.status) {
      case "SUCCESS": {
        set_success_message("Password reset link has been sent to your email if an account exists")
        set_password_reset_email_is_sent(true)
        set_is_awaiting(false)

        // Always break
        break
      }
      case "EMAIL_ADDRESS_DOES_NOT_EXIST": {
        set_success_message("Password reset link has been sent to your email if an account exists")
        set_password_reset_email_is_sent(true)
        set_is_awaiting(false)

        // Always break
        break
      }
      case "USER_IS_INACTIVE": {
        alert("Your account is inactive. Please contact the owner of your account.")
        set_password_reset_email_is_sent(true)
        set_is_awaiting(false)

        // Always break
        break
      }
      case "WRONG_AUTHENTICATION_TYPE": {
        alert("Your authentication type is not password.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "USER_DID_NOT_COMPLETE_SIGNUP_AND_EMAIL_NOT_VALIDATED": {
        alert("Please complete signup.")

        // Redirect to validate-email page
        navigate(`/validate-email?email_validation_token_id=${post_auth_password_reset_email_res.data.email_validation_token_id}&user_id=${post_auth_password_reset_email_res.data.user_id}`)

        // Always break
        break
      }
      case "USER_DID_NOT_COMPLETE_SIGNUP_AND_PASSWORD_NOT_SET": {
        alert("Please complete signup.")

        // // Redirect to set-password page
        // navigate(`/set-password?user_id=${post_auth_password_reset_email_res.data.user_id}`)

        // Redirect to validate-email page
        navigate(`/validate-email?email_validation_token_id=${post_auth_password_reset_email_res.data.email_validation_token_id}&user_id=${post_auth_password_reset_email_res.data.user_id}`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }



  // Validate email_validation_token
  const validate_email_validation_token = async () => {

    // Execute validate email_validation_token
    const post_validate_email_validation_token_res = await axios.post(`/api/email-validation-tokens/${email_validation_token_id}/validate`, {
      code: code,
      type: "password_reset"
    })

    if (!post_validate_email_validation_token_res.data.success) {
      switch (post_validate_email_validation_token_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to login page
          navigate(`/login`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_validate_email_validation_token_res.data.status) {
      case "VALID": {
        set_email_validation_token_is_validated(true)
        set_user_id(post_validate_email_validation_token_res.data.user_id)
        set_password_reset_token_id(post_validate_email_validation_token_res.data.password_reset_token_id)

        // Always break
        break
      }
      case "INVALID": {
        alert("Invalid link. Please try again.")

        // Redirect to vanilla reset page
        navigate(`/reset`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }


  // Reset password
  const reset_password = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate password
    if (!validate_password(password)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Password must be at least 8 characters long and must include a lowercase alphabet, an uppercase alphabet, a number, and a special character")

      // End of the line
      return
    }

    // Validate password confirm
    if (password !== password_confirm) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Passwords do not match.")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK


    // Hash master password prepare for API call
    const password__unenc__uint8_array = string_to_uint8_array(password)
    const password__hash__uint8_array = await HASH(password__unenc__uint8_array)
    const password__hash__base64 = uint8_array_to_base64(password__hash__uint8_array)

    // Execute reset password
    const put_user_password_res = await axios.put(`/api/users/${user_id}/password`, {
      password_reset_token_id: password_reset_token_id,
      type: "forgot_password",
      password__hash: password__hash__base64
    })

    if (!put_user_password_res.data.success) {
      switch (put_user_password_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to login page
          navigate(`/login`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_user_password_res.data.status) {
      case "SUCCESS": {
        set_password_is_reset(true)
        set_is_awaiting(false)
        set_success_message("Your password has been reset. You can now log in with your new password.")

        // Always break
        break
      }
      case "USER_ID_DOES_NOT_MATCH":
      case "TOKEN_TYPE_IS_WRONG": {
        alert("Error occurred with password reset. Please try again.")

        navigate(`/reset`)

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        alert(put_user_password_res.data.error_message)

        navigate(`/reset`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders
  useEffect(() => {
    // Reset password mode
    if (email_validation_token_id || code) {
      validate_email_validation_token()
    }
    // Send password reset email mode
    else {
      email_address_ref.current.focus()
    }

    // Mixpanel tracking
    mixpanel_client_track("app_reset_visited", null)
  }, [])

  // Focus on new password field
  useEffect(() => {
    if (email_validation_token_is_validated) {
      password_ref.current.focus()
    }
  }, [email_validation_token_is_validated])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
          <ResponsiveLogoMarkOnly className="h-8 w-auto" />
        </div>
        {email_validation_token_id || code
        ? // Reset password
          email_validation_token_is_validated 
          ? <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-medium">New password</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        Password must be at least 8 characters long and must include a lowercase alphabet, an uppercase alphabet, a number, and a special character.
                      </span>
                    </span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      ref={password_ref}
                      value={password}
                      onChange={(e) => handle_user_input("password", e.target.value)}
                      onKeyDown={(e) => handle_enter_keydown(e, reset_password)}
                      disabled={password_is_reset}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Confirm new password
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={password_confirm}
                      onChange={(e) => handle_user_input("password_confirm", e.target.value)}
                      onKeyDown={(e) => handle_enter_keydown(e, reset_password)}
                      disabled={password_is_reset}
                    />
                  </div>
                </div>

                {error_message
                ? <div className="flex space-x-2 items-start">
                    <div>
                      <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                    </div>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}
                {password_is_reset && success_message
                ? <div className="flex space-x-2 items-start">
                    <div>
                      <CheckCircleIcon className="pt-[3px] w-4 h-4 text-green-600 h-full"/>
                    </div>
                    <div className="text-sm font-medium text-green-600">{success_message}</div>
                  </div>
                : <></>}

                <div>
                  <button
                    type="submit"
                    className={password_is_reset ? "flex w-full justify-center rounded-md bg-blue-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" : "flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
                    onClick={reset_password}
                    disabled={password_is_reset}
                  >
                    {is_awaiting ? (
                      <ReactLoading
                        type='spokes'
                        color='#ffffff'
                        height={20}
                        width={20}
                      />
                    ) : (
                      <span>Reset password</span>
                    )}
                  </button>
                </div>

                {password_is_reset
                ? <p className="mt-10 text-center text-sm text-gray-500">
                    <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                      Login
                    </Link>
                  </p>
                : <></>}
              </div>
            </div>
          : <div className="mt-10 flex justify-center items-center">
              <ReactLoading
                type='spokes'
                color='#343D46'
                height={20}
                width={20}
              />
            </div> 
        : // Send password reset email 
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Enter email to reset password
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    ref={email_address_ref}
                    value={email_address}
                    onChange={(e) => handle_user_input("email_address", e.target.value)}
                    onKeyDown={(e) => handle_enter_keydown(e, send_password_reset_email)}
                    disabled={password_reset_email_is_sent}
                  />
                </div>
              </div>

              {error_message
              ? <div className="flex space-x-2 items-start">
                  <div>
                    <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                  </div>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}
              {password_reset_email_is_sent && success_message
              ? <div className="flex space-x-2 items-start">
                  <div>
                    <CheckCircleIcon className="pt-[3px] w-4 h-4 text-green-600 h-full"/>
                  </div>
                  <div className="text-sm font-medium text-green-600">{success_message}</div>
                </div>
              : <></>}

              <div>
                <button
                  type="submit"
                  className={password_reset_email_is_sent ? "flex w-full justify-center rounded-md bg-blue-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" : "flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
                  onClick={send_password_reset_email}
                  disabled={password_reset_email_is_sent}
                >
                  {is_awaiting ? (
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={20}
                      width={20}
                    />
                  ) : (
                    <span>Send password reset email</span>
                  )}
                </button>
              </div>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              No account?{" "}
              <Link to="/signup" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Sign up
              </Link>
            </p>
          </div>
        }
      </div>
    </div>
  )
}

export default ResetPage