
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, Link, useParams } from "react-router-dom"


import axios from "axios"

import ReactLoading from "react-loading"

import { ResponsiveLogoMarkOnly } from '../../../../../components/logo'



const TrackingProgramsProgramIDLinkPage = ({
  
} : {
  
}) => {

  // Navigate
  const navigate = useNavigate()

  const { program_id } = useParams()


  // States
  const [program, set_program] = useState({})
  const [user, set_user] = useState({})
  const [campaign, set_campaign] = useState({})

  const [program_is_fetched, set_program_is_fetched] = useState(false)



  const get_program = async () => {

    // Set is_fetched to false
    set_program_is_fetched(false)

    // Execute get campaign data
    const post_program_res = await axios.post(`/tracking/programs/${program_id}/link`, {})

    if (!post_program_res.data.success) {
      switch (post_program_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_user(post_program_res.data.user)
    set_program(post_program_res.data.program)
    set_campaign(post_program_res.data.campaign)

    // If there failure redirect is enabled, redirect to the URL
    if (post_program_res.data.campaign.campaign_metadata.simulation_failure_redirect_is_enabled) {
      window.location.href = post_program_res.data.campaign.campaign_metadata.simulation_failure_redirect_url
      return
    }
    // Default failure
    else {
      navigate(`/failure/programs/${program_id}`)
      return
    }



    // set_program_is_fetched(true)
  }



  useEffect(() => {

    get_program()


  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      {program_is_fetched
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
          <ResponsiveLogoMarkOnly className="h-8 w-auto" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {/* TEMP */}
          {/* <div className="space-y-4">
            <div className="block text-sm font-medium leading-6 text-gray-900">
              {`Hi ${user["user_metadata"]["first_name"]}!`}
            </div>

            <div className="block text-sm font-medium leading-6 text-gray-900">
              {`Oops, you clicked on a (fake) phishing link that was sent to you as part of an email phishing simulation. Don't worry, this simulation was assigned by your account and wasn't a real phishing attempt.`}
            </div>

            {campaign["campaign_metadata"]["simulation_failure_training_is_enabled"]
            ? <>
              <div className="block text-sm font-medium leading-6 text-gray-900">
                {`By clicking below, you'll be directed to a personalized training module where we'll go over what went wrong in your specific simulation! This should only take 5 minutes, and is assigned as a requirement by your account. Please complete this at your earliest availability.`}
              </div>
              <div>
                <Link
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  to={`https://chat.vansec.com/programs/${program_id}`}
                >
                  <span>Proceed to personalized training</span>
                </Link>
              </div>
            </>
            : <></>}
          </div> */}

          {/* Sign up link */}
          <p className="mt-10 text-center text-sm text-gray-500">
            {/* TEXT */}
          </p>


        </div>
      </div>
      : <ReactLoading
        type='spokes'
        color='#343D46'
        height={20}
        width={20}
      />}
    </div>
  )
}

export default TrackingProgramsProgramIDLinkPage