

// **************************************
// Options
// **************************************


export const account_plan_options = [
  "alpha", 
  "trial", 
  "companions_on_the_go", 
  "custom"
]


export const user_type_options = [
  "end_user",
  "admin",
  "owner"
]

export const campaign_type_options = [
  "simulation",
  "training"
]

export const campaign_delivery_type_options = [
  "window",
  "same",
]

export const simulation_program_status_options = [
  "none",
  "draft",
  "active",
  "reported",
  "failed",
  "terminated",
]

export const training_program_status_options = [
  "none",
  "draft",
  "active",
  "completed",
  "terminated",
]


// export const department_options = [
//   "C-Suite",
//   "IT/Security",
//   "Sales",
//   "Marketing",
//   "Engineering",
//   "Procurement",
//   "Finance",
//   "Legal",
//   "Accounting",
//   "Product",
//   "Design",
//   "Human Resources",
//   "Public Relations",
//   "Investor Relations",
//   "Business Development",
//   "Operations",
//   "Quality Assurance",
//   "Research & Development",
//   "Other",
// ]

// export const position_options = [
//   "CISO",
//   "CTO",
//   "CEO",
//   "COO",
//   "Security Engineer",
//   "IT Engineer",
//   "Product Manager",
//   "Software Engineer",
//   "Other",
// ]



export const user_property_options = [
  // Profile properties
  "first_name", // user_doc.user_metadata.first_name
  "last_name", // user_doc.user_metadata.last_name
  "email_address", // user_doc.email_address
  "phone_number", // user_doc.phone_number
  "birthday", // user_doc.user_metadata.birthday
  "sex", // user_doc.user_metadata.sex
]

export const required_user_property_options = [
  "first_name",
  "last_name",
  "email_address",
  "position",
]


export const editable_user_property_options = [
  "first_name",
  "last_name", 
  "email_address",
  "position", 
  "department", 
  "division", 
  "ORGANIZATION", 
  "manager_id", 
  "manager_name", 
  "preferred_language",
  "city", 
  "country", 
  "locale", 
  "timezone", 
  "phone_number",
  "social_linkedin_url",
  "social_instagram_url", 
  "social_facebook_url", 
]









export const companion_property_options = [
  // Profile properties
  "first_name", // companion_doc.companion_metadata.first_name
  "last_name", // companion_doc.companion_metadata.last_name
  "email_address", // companion_doc.email_address
  "phone_number", // companion_doc.phone_number
  "birthday", // companion_doc.companion_metadata.birthday
  "sex", // companion_doc.companion_metadata.sex
  "voice", // companion_doc.companion_metadata.voice
  "type", // companion_doc.companion_metadata.type
  "personality_prompt", // companion_doc.companion_metadata.personality_prompt
  "additional_prompt", // companion_doc.companion_metadata.additional_prompt
  "primary_assigned_member_first_name", // companion_doc.companion_metadata.assigned_members[0].member_metadata.first_name
  "primary_assigned_member_last_name", // companion_doc.companion_metadata.assigned_members[0].member_metadata.last_name
]


export const companion_type_options = [
  "friend",
]


export const companion_voice_options = [
  "female",
  "male"
]

export const companion_plan_options = [
  "alpha", 
  "trial", 
  "basic", 
  "custom"
]


export const member_property_options = [
  // Profile properties
  "first_name", // member_doc.member_metadata.first_name
  "last_name", // member_doc.member_metadata.last_name
  "email_address", // member_doc.email_address
  "phone_number", // member_doc.phone_number
  "birthday", // member_doc.member_metadata.birthday
  "sex", // member_doc.member_metadata.sex
  "favorite_topics", // member_doc.member_metadata.favorite_topics
  "avoid_topics", // member_doc.member_metadata.avoid_topics
  "history_prompt", // member_doc.member_metadata.history_prompt
  "additional_prompt", // member_doc.member_metadata.additional_prompt
  "primary_guardian_user_first_name", // member_doc.member_metadata.guardian_users[0].user_metadata.first_name
  "primary_guardian_user_last_name", // member_doc.member_metadata.guardian_users[0].user_metadata.last_name
  "primary_guardian_user_relationship" // member_doc.member_metadata.guardian_users[0].relationship
]



export const sex_options = [
  "female",
  "male",
]





export const query_by_options = [
  "area_code",
  "city",
  "state",
  "postal_code"
]






export const receptionist_action_options = [
  "schedule_appointment",
]











export const campaign_user_property_options = [
  "first_name",
  "last_name", 
  "email_address",
  "position", 
  "department", 
  "division", 
  "ORGANIZATION", 
  "manager_id",
  "manager_name", 
  "preferred_language",
  "city", 
  "country", 
  "locale", 
  "timezone", 
  "phone_number",
  "social_linkedin_url",
  "social_instagram_url", 
  "social_facebook_url", 
]






export const program_property_options = [
  "first_name", // program_doc.user_metadata.first_name
  "last_name", // program_doc.user_metadata.last_name
  "email_address", // program_doc.email_address
  "simulation_status", // program_doc.simulation_status
  "training_status", // program_doc.training_status
  "initial_email_is_sent", // (complex logic)
  "initial_email_status", // (complex logic)
  "open_email_is_done", // "open_email" in program_doc.actions
  "report_email_is_done", // "report_email" in program_doc.actions
  "open_link_is_done", // "open_link" in program_doc.actions
  "submit_website_is_done", // "submit_website" in program_doc.actions
  "reply_email_is_done", // "reply_email" in program_doc.actions
  "reply_email_objective_is_done", // "reply_email_objective" in program_doc.actions
  "reply_message_is_done", // "reply_message" in program_doc.actions
  "reply_message_objective_is_done", // "reply_message_objective" in program_doc.actions
  "program_created_at", // program_doc.program_created_at
  "name", // program_doc.program_metadata.name
]



export const job_status_options = [
  "none",
  "email_delivered",
  "email_deferred",
  "email_bounced",
  "message_delivered",
]





export const filter_options = [
  "includes_text",
  "equals_text",
  "equals_option",
  "before_date",
  "after_date",
  "equals_number",
  "lte_number",
  "gte_number",
  "lt_number",
  "gt_number",
]



export const provision_type_options = [
  "disabled",
  "native",
  "scim",
]

export const authentication_type_options = [
  "disabled",
  "password",
  "sso",
]


export const authentication_sso_method_options = [
  "google_oauth",
]


export const receptionist_mode_options = [
  "front_desk",
  "after_hours"
]


export const billing_cycle_options = [
  { id: 1, value: "yearly", title: 'Yearly', primary_price: '$500.00 / user / year', secondary_price: "", description: '' },
  { id: 2, value: "monthly", title: 'Monthly', primary_price: '$50.00 / user / month', secondary_price: "$600.00 / user / year", description: '' },
]






export const simulation_single_send_objective_options = [
  "open_link",
  "open_link_submit_website",
  // "open_attachment",
]

export const simulation_multi_message_objective_options = [
  "open_link",
  "open_link_submit_website",
  // "open_attachment", 
  "reply_info", 
]


export const simulation_default_domain_options = [
  "admin-settings.com",
  // "google-homepage.com"
]


export const training_module_options = [
  "ransomware",
  "malware",
  "phishing",
  "spear_phishing",
  // "smishing",
  // "vishing",
  // "deep_fake",
  // "business_email_compromise",
  // "digital_footprint",
  // "linkedin_footprint",
  // "ceo_fraud",
  // "passwords",
  // "mfa",
  // "personal_data",
  // "mobile_phone",
  // "tailgating",
  // "out_of_office",
  // "privacy_by_design",
  "soc2_training",
  "iso27001_training",
  "hipaa_training",
  "pci_training",
  "gdpr_training", 
  "ccpa_training",
]



export const simulation_attachment_extension_options = [
  "pdf",
  "exe"
]





export const get_year_options = () => {
  const current_year = new Date().getFullYear()
  return Array.from(
    { length: current_year - 1900 + 1 }, 
    (_, i) => (current_year - i).toString()
  );
}

export const get_month_options = () => {
  return Array.from(
    { length: 12 }, 
    (_, i) => (i + 1).toString().padStart(2, "0")
  );
}

export const get_day_options = (year, month) => {
  // Check if inputs are valid
  const valid_year = /^\d{4}$/.test(year) && year >= "1900" && year <= "2025";
  const valid_month = /^\d{2}$/.test(month) && month >= "01" && month <= "12";
  if (!valid_year || !valid_month) return [];

  // Calculate the number of days in the given month and year
  const days_in_month = new Date(parseInt(year, 10), parseInt(month, 10), 0).getDate();
  return Array.from(
    { length: days_in_month }, 
    (_, i) => (i + 1).toString().padStart(2, "0")
  );
};