
import { useEffect, useState } from 'react'

import ReactLoading from "react-loading"

import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'

import { useLocation, useNavigate, useParams, Link } from "react-router-dom"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon, ArrowLongLeftIcon } from '@heroicons/react/24/outline'

import { auth_axios } from '../../../../libs/authWeb';
import { mixpanel_client_track } from '../../../../libs/mixpanelClient';
import { format_phone_number, get_member_property_value } from '../../../../libs/formats'
import { classNames } from '../../../../libs/helpers'


import DeactivateMembersModal from '../DeactivateMembersModal'
import ReactivateMembersModal from '../ReactivateMembersModal'
import DeleteMembersModal from '../DeleteMembersModal'




const Event = ({
  event,
  event_checked_map,
  set_event_checked_map,
} : {
  event : any
  event_checked_map : any
  set_event_checked_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(event_checked_map[event.event_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={() => navigate(`/dashboard/events/${event.event_id}`)}
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {event_checked_map[event.event_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={event_checked_map[event.event_id]}
          onChange={(e) =>
            set_event_checked_map({
            ...event_checked_map,
            [event.event_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>


      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          event_checked_map[event.event_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {event.campaign_metadata.name}
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {campaign_type_map[event.campaign_metadata.type]}
      </td> */}
      <td 
        className={classNames((() => {
          switch (event.simulation_status) {
            case "none": {
              return "text-gray-500"
            }
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "reported": {
              return "text-green-500"
            }
            case "failed": {
              return "text-red-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {/* {event_status_map[event.simulation_status]} */}
      </td>
      <td 
        className={classNames((() => {
          switch (event.training_status) {
            case "none": {
              return "text-gray-500"
            }
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "completed": {
              return "text-green-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {/* {event_status_map[event.training_status]} */}
      </td>
    </tr>
  )
}


const MembersMemberIdPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { member_id } = useParams()  

  // States
  const [member_data, set_member_data] = useState({})
  const [events, set_events] = useState([])

  const [active_event_checked_map, set_active_event_checked_map] = useState({})
  const [inactive_event_checked_map, set_inactive_event_checked_map] = useState({})

  const [member_data_and_events_are_fetched, set_member_data_and_events_are_fetched] = useState(false)
  const [current_tab, set_current_tab] = useState("active")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Modal
  const [deactivate_members_modal_is_open, set_deactivate_members_modal_is_open] = useState(false)
  const [reactivate_members_modal_is_open, set_reactivate_members_modal_is_open] = useState(false)
  const [delete_members_modal_is_open, set_delete_members_modal_is_open] = useState(false)

  // Search & filters
  const [search_text, set_search_text] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)


  // Handle member input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_member_data_and_events = async () => {
    // Set is_fetched to false
    set_member_data_and_events_are_fetched(false)

    // Execute get member data
    const get_member_data_res = await auth_axios.get(`/api/members/${member_id}`)

    if (!get_member_data_res.data.success) {
      switch (get_member_data_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // // Execute get events
    // const get_member_events_res = await auth_axios.get(`/api/members/${member_id}/events`)

    // if (!get_member_events_res.data.success) {
    //   switch (get_member_events_res.data.status) {
    //     case "FATAL_ERROR": {
    //       alert("Fatal error")
          
    //       // Always break
    //       break
    //     }
    //     default: {
    //       // Always break
    //       break
    //     }
    //   }
    //   return
    // }

    // Set states
    set_member_data(get_member_data_res.data.member_data)
    // set_events(get_member_events_res.data.events)
    // set_active_event_checked_map(get_member_events_res.data.events.filter(event => event.active).reduce((acc, event) => ({...acc, [event.event_id]: false}), {}))
    // set_inactive_event_checked_map(get_member_events_res.data.events.filter(event => !event.active).reduce((acc, event) => ({...acc, [event.event_id]: false}), {}))
    set_member_data_and_events_are_fetched(true)
  }

  const toggle_all_active_events = (e) => {
    set_active_event_checked_map(Object.keys(active_event_checked_map).reduce((acc, event_id) => ({ ...acc, [event_id]: e.target.checked }), {}))
  }

  const toggle_all_inactive_events = (e) => {
    set_inactive_event_checked_map(Object.keys(inactive_event_checked_map).reduce((acc, event_id) => ({ ...acc, [event_id]: e.target.checked }), {}))
  }

  const uncheck_all_active_events = () => {
    set_active_event_checked_map(Object.keys(active_event_checked_map).reduce((acc, event_id) => ({ ...acc, [event_id]: false }), {}))
  }

  const uncheck_all_inactive_events = () => {
    set_inactive_event_checked_map(Object.keys(inactive_event_checked_map).reduce((acc, event_id) => ({ ...acc, [event_id]: false }), {}))
  }

  // Renders  
  // render upon load & upon action_fetch_start_index change
  useEffect(() => {

    get_member_data_and_events()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_members_member_id_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Link to={`/dashboard/members`} className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>
          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">{member_data_and_events_are_fetched && `${get_member_property_value(member_data, "first_name", true)} ${get_member_property_value(member_data, "last_name", true)}`}</h1>
          <div className="mt-1 text-sm leading-6 text-gray-500">
            {member_data_and_events_are_fetched
              ? get_member_property_value(member_data, "phone_number", true)
              : <ReactLoading
                type='spokes'
                color='#000'
                height={20}
                width={20}
              />}
          </div>
        </div>
        <Link
          type="button"
          className={classNames(!member_data_and_events_are_fetched ? "bg-blue-500" : "bg-blue-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")} 
          to={`edit`}
        >
          Edit member
        </Link>
        
      </div>

      {/* Data */}
      <div className="mt-8 flow-root">

        {/* Member information */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">

          {/* First name */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">First name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{get_member_property_value(member_data, "first_name", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Last name */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Last name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{get_member_property_value(member_data, "last_name", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Email address */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{get_member_property_value(member_data, "email_address", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Phone number */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{get_member_property_value(member_data, "phone_number", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>


          {/* Birthday */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Birthday</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{get_member_property_value(member_data, "birthday", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Sex */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Sex</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{get_member_property_value(member_data, "sex", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Primary guardian */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Guardian</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {member_data_and_events_are_fetched ? (
                  <span>{`${get_member_property_value(member_data, "primary_guardian_user_first_name", true)} ${get_member_property_value(member_data, "primary_guardian_user_last_name", true)} (${get_member_property_value(member_data, "primary_guardian_user_relationship", true)})`}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>
          
          {/* Additional attributes */}
          <Disclosure as="div" className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                    <span className="font-medium text-sm">Additional properties</span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                      ) : (
                        <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="div" className="mt-8 space-y-6 divide-y divide-gray-100 text-sm leading-6">
                  
                  {/* Favorite topics */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Favorite topics</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {member_data_and_events_are_fetched ? (
                          <span>{get_member_property_value(member_data, "favorite_topics", true)}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Avoid topics */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Topics to avoid</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {member_data_and_events_are_fetched ? (
                          <span>{get_member_property_value(member_data, "avoid_topics", true)}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Alert topics */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Topics to alert guardian user about</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {member_data_and_events_are_fetched ? (
                          <span>{get_member_property_value(member_data, "alert_topics", true)}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* History prompt */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Personal history</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {member_data_and_events_are_fetched ? (
                          <span>{get_member_property_value(member_data, "history_prompt", true)}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Additional prompt */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Additional information</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {member_data_and_events_are_fetched ? (
                          <span>{get_member_property_value(member_data, "additional_prompt", true)}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          
        </dl>

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Active tab */}
              <div
                className={classNames(
                  current_tab === "active"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_inactive_events(); set_current_tab("active") }}
              >
                Events
                <span
                  className={classNames(
                    current_tab === "active" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {events.filter(event => event.active).length}
                </span>
              </div>

              {/* Inactive tab */}
              <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_events(); set_current_tab("inactive") }}
              >
                Archived events
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {events.filter(event => !event.active).length}
                </span>
              </div>
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">

          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            <div>
              {Object.values(active_event_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(active_event_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  // onClick={() => set_terminate_events_modal_is_open(true)}
                >
                  Terminate selected
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  // onClick={() => set_deactivate_events_modal_is_open(true)}
                >
                  Archive selected
                </button>
              </div>
              : <></>}
              {Object.values(inactive_event_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(inactive_event_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  // onClick={() => set_delete_events_modal_is_open(true)}
                >
                  Delete selected
                </button>
              </div>
              : <></>}
            </div>

            {/* Search bar */}
            {/* <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div> */}
          </div>

          {/* Table => Turn this into Events related to the member*/}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={(() => {
                            switch(current_tab) {
                              case "active": {
                                return Object.values(active_event_checked_map).length > 0 && Object.values(active_event_checked_map).every(is_checked => is_checked)
                              }
                              case "inactive": {
                                return Object.values(inactive_event_checked_map).length > 0 && Object.values(inactive_event_checked_map).every(is_checked => is_checked)
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                          onChange={(() => {
                            switch(current_tab) {
                              case "active": {
                                return toggle_all_active_events
                              }
                              case "inactive": {
                                return toggle_all_inactive_events
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                        />
                      </th>
                      <th scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Campaign name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Event status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {member_data_and_events_are_fetched
                    ? events.filter((() => {
                      switch(current_tab) {
                        case "active": {
                          return event => event.active
                        }
                        case "inactive": {
                          return event => !event.active
                        }
                        default: {
                          return
                        }
                      }
                    })())
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Danger zone */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">
          <div className="pt-6 sm:flex">
            <dt className="text-red-500 sm:w-64 sm:flex-none sm:pr-6 flex">
              <span className="font-semibold">Danger zone</span>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-red-500">
                {/* TODO */}
              </div>
              {member_data["active"]
              ? <button
                type="button"
                className={classNames(!member_data_and_events_are_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                onClick={() => set_deactivate_members_modal_is_open(true)}
                disabled={!member_data_and_events_are_fetched}
              >
                Deactivate member
              </button>
              : <div className="flex space-x-4">
                <button
                  type="button"
                  className={classNames(!member_data_and_events_are_fetched ? "bg-blue-500" : "bg-blue-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")} 
                  onClick={() => set_reactivate_members_modal_is_open(true)}
                  disabled={!member_data_and_events_are_fetched}
                >
                  Reactivate member
                </button>
                <button
                  type="button"
                  className={classNames(!member_data_and_events_are_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                  onClick={() => set_delete_members_modal_is_open(true)}
                  disabled={!member_data_and_events_are_fetched}
                >
                  Delete member
                </button>
              </div>}
            </dd>
          </div>
        </dl>
        
      </div>


      {/* Modals */}
      <DeactivateMembersModal 
        is_open={deactivate_members_modal_is_open}
        set_is_open={set_deactivate_members_modal_is_open}
        member_ids={[member_id]}
        set_refresh_variable={set_refresh_variable}
      />
      <ReactivateMembersModal 
        is_open={reactivate_members_modal_is_open}
        set_is_open={set_reactivate_members_modal_is_open}
        member_ids={[member_id]}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteMembersModal 
        is_open={delete_members_modal_is_open}
        set_is_open={set_delete_members_modal_is_open}
        member_ids={[member_id]}
        set_refresh_variable={() => null} // Don't refresh, since the member no longer exists
      />
    </div>
  )
}

export default MembersMemberIdPage