
import { useEffect, useState, useRef, Fragment } from 'react'
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom"


import { Switch } from '@headlessui/react'
import { InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { ReactComponent as GoogleLogo } from "../../media/oauth-logos/google.svg"
import { v4 as uuidv4 } from 'uuid'


import axios from "axios"

import { ResponsiveLogoMarkOnly } from '../../components/logo'

import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'

import { handle_enter_keydown, classNames } from '../../libs/helpers'
import { validate_email_address } from '../../libs/validate'

import { mixpanel_client_track } from '../../libs/mixpanelClient'
import { IS_DEV } from '../../libs/env'






const SignupPage = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Search params
  const [search_params, _] = useSearchParams()
  
  // User input
  const [email_address, set_email_address] = useState("")
  const [is_agreed, set_is_agreed] = useState(false)

  // Params
  const [user_id, set_user_id] = useState("")
  const [account_id, set_account_id] = useState("")
  const [is_owner_signup, set_is_owner_signup] = useState(true)

  // Autofocus on account name and email address field
  const email_address_ref = useRef<any>(null)

  // Error message
  const [error_message, set_error_message] = useState("")

  // Awaiting
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  const validate_params = () => {
    const param_user_id = search_params.get("user_id")
    const param_account_id = search_params.get("account_id")
    const param_email_address = search_params.get("email_address")

    if (param_user_id && param_account_id) {
      set_user_id(param_user_id)
      set_account_id(param_account_id)
      set_email_address(param_email_address || "")
      set_is_owner_signup(false)
    }
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "is_agreed": {
        set_is_agreed(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const redirect_to_google_oauth = () => {
    const nonce = uuidv4()

    const param_user_id = search_params.get("user_id")
    const param_account_id = search_params.get("account_id")

    if (param_user_id && param_account_id) {
      window.location.href = IS_DEV ? `http://localhost:5000/api/auth/google?state=${nonce}+${param_user_id}+${param_account_id}` : `https://app.aldermate.com/api/auth/google?state=${nonce}+${param_user_id}+${param_account_id}`
    }
    else {
      window.location.href = IS_DEV ? `http://localhost:5000/api/auth/google?state=${nonce}` : `https://app.aldermate.com/api/auth/google?state=${nonce}`
    }
  }

  // Submit function
  const submit = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (is_owner_signup) {
      // // Validate account name
      // if (!account_name) {
      //   reset()
  
      //   // Show error message
      //   set_error_message("Please provide the name of your business")
  
      //   // End of the line
      //   return
      // }
    }

    // Validate email address
    if (!validate_email_address(email_address)) {
      reset()

      // Show error message
      set_error_message("Invalid email address")

      // End of the line
      return
    }

    // Validate is_agreed
    if (!is_agreed) {
      reset()

      // Show error message
      set_error_message("Please agree to the Terms of Service and Privacy Policy")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK


    // Execute signup
    const post_auth_signup_email_res = await axios.post(`/api/auth/signup/email`, {
      is_owner_signup: is_owner_signup,
      user_id: user_id,
      account_id: account_id,
      email_address: email_address,
    })

    if (!post_auth_signup_email_res.data.success) {
      switch (post_auth_signup_email_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to signup
          navigate(`/signup`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_signup_email_res.data.status) {
      
      case "ACCOUNT_AND_USER_CREATED_AND_EMAIL_VALIDATION_CODE_SENT": {
        // Redirect to validate-email page
        navigate(`/validate-email?email_validation_token_id=${post_auth_signup_email_res.data.email_validation_token_id}&user_id=${post_auth_signup_email_res.data.user_id}${post_auth_signup_email_res.data.first_name ? `&first_name=${post_auth_signup_email_res.data.first_name}` : ""}${post_auth_signup_email_res.data.last_name ? `&last_name=${post_auth_signup_email_res.data.last_name}` : ""}`)
        
        // Always break
        break
      }
      case "NON_OWNER_USER_SETUP_AND_EMAIL_VALIDATION_CODE_SENT": {
        // Redirect to validate-email page
        navigate(`/validate-email?email_validation_token_id=${post_auth_signup_email_res.data.email_validation_token_id}&user_id=${post_auth_signup_email_res.data.user_id}${post_auth_signup_email_res.data.first_name ? `&first_name=${post_auth_signup_email_res.data.first_name}` : ""}${post_auth_signup_email_res.data.last_name ? `&last_name=${post_auth_signup_email_res.data.last_name}` : ""}`)
        
        // Always break
        break
      }
      case "ACCOUNT_AND_USER_EXISTS": {
        alert("You already have an account. Please login.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "ACCOUNT_EXISTS_AND_UNWELCOME_EMAIL_ADDRESS": {
        alert("Please contact the owner of your Aldermate account.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "ACCOUNT_AND_USER_EXISTS": {
        alert("You already have an account. Please login.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "WRONG_AUTHENTICATION_TYPE": {
        alert("You chose the wrong authentication method. Please try again")

        // Reset
        reset()

        // Always break
        break
      }
      case "CANNOT_USE_THIS_EMAIL": {
        alert("You cannot use this email address. Please try again")

        // Reset email
        set_email_address(search_params.get("email_address"))

        // Reset
        reset()

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders
  useEffect(() => {

    // Focus on account name field upon load
    email_address_ref.current.focus()

    // Validate search params
    validate_params()
    
    // Mixpanel tracking
    mixpanel_client_track("app_signup_visited", null)
    
  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
          <ResponsiveLogoMarkOnly className="h-8 w-auto" />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

          <div className="space-y-4">

            {/* Email address */}
            <div>
              <label className="block text-sm leading-6 text-gray-900 flex">
                <span className="font-medium">Email address</span>
                <span className="flex relative items-center">
                  <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                  <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                    Email address for your account that will be used for owner (admin) privileges. Your email must belong to your company domain.
                  </span>
                </span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  // className={classNames(
                  //   is_owner_signup ? "" : "bg-gray-100",
                  //   "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  // )}
                  ref={email_address_ref}
                  value={email_address}
                  onChange={(e) => handle_user_input("email_address", e.target.value)}
                  onKeyDown={(e) => handle_enter_keydown(e, submit)}
                  // disabled={!is_owner_signup}
                />
              </div>
            </div>
            
            {/* Agree */}
            <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
              <div className="flex h-6 items-center">
                <Switch
                  checked={is_agreed}
                  onChange={(e) => handle_user_input("is_agreed", e)}
                  className={classNames(
                    is_agreed ? 'bg-blue-600' : 'bg-gray-200',
                    'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                >
                  <span className="sr-only">Agree to policies</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      is_agreed ? 'translate-x-3.5' : 'translate-x-0',
                      'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
              <div>
                <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                  By selecting this, you agree to our{' '}
                </Switch.Label>
                <Link to="https://app.aldermate.com/terms" target="_blank" className="font-semibold text-blue-600 text-sm leading-6">
                  Terms&nbsp;of&nbsp;Service
                </Link>
                <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                  {" "}and{" "}
                </Switch.Label>
                <Link to="https://app.aldermate.com/privacy" target="_blank" className="font-semibold text-blue-600 text-sm leading-6">
                  Privacy&nbsp;Policy
                </Link>
                <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                  .
                </Switch.Label>
              </div>
              
            </Switch.Group>
          
            {/* Error message */}
            {error_message
            ? <div className="flex space-x-2 items-start">
                <div>
                  <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                </div>
                <div className="text-sm font-medium text-red-400">{error_message}</div>
              </div>
            : <></>}

            {/* Continue button */}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={submit}
              >
                {is_awaiting ? (
                  <ReactLoading
                    type='spokes'
                    color='#ffffff'
                    height={20}
                    width={20}
                  />
                ) : (
                  <span>Continue</span>
                )}
              </button>
            </div>

          </div>



          {/* SSO options */}
          <div className="mt-10 flex flex-col justify-center items-center space-y-4">
            <div className="text-sm text-gray-500">
              Or sign up with
            </div>
            <div className="flex space-x-4">
              {/* Google */}
              <button
                onClick={redirect_to_google_oauth}
                className="flex items-center space-x-1 hover:scale-105"
              >
                <GoogleLogo 
                  className="h-4 w-4"
                />
                <div className="text-sm">Google</div>
              </button>

              {/* Microsoft */}
              {/* <Link
                to={`/api/auth/google`}
                className="flex items-center space-x-1"
              >
                <GoogleLogo 
                  className="h-4 w-4"
                />
                <div className="text-sm">Google</div>
              </Link> */}

                
            </div>
          </div>



          {/* Login link */}
          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
              Log in
            </Link>
          </p>
            
        </div>
      </div>
    </div>
  )
}

export default SignupPage