
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom"


import axios from "axios"
import {  set as idb_set } from 'idb-keyval'
import { v4 as uuidv4 } from 'uuid'

import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'

import { HASH } from '../../libs/crypto'
import { string_to_uint8_array, uint8_array_to_base64 } from '../../libs/converters'
import { handle_enter_keydown } from '../../libs/helpers'
import { validate_email_address } from '../../libs/validate'

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ReactComponent as GoogleLogo } from "../../media/oauth-logos/google.svg"
import { ResponsiveLogoMarkOnly } from '../../components/logo'


import { mixpanel_client_track } from '../../libs/mixpanelClient'
import { IS_DEV } from '../../libs/env'





const LoginPage = ({

} : {

}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // User input
  const [email_address, set_email_address] = useState("")
  const [password, set_password] = useState("")

  // Autofocus on email address field
  const email_address_ref = useRef<any>(null);

  // Error message
  const [error_message, set_error_message] = useState("")
  const [error_message_is_displayed, set_error_message_is_displayed] = useState(false)

  // Awaiting
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "password": {
        set_password(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
    set_error_message_is_displayed(false)
  }

  const redirect_to_google_oauth = () => {
    const nonce = uuidv4()

    window.location.href = IS_DEV ? `http://localhost:5000/api/auth/google?state=${nonce}` : `https://app.aldermate.com/api/auth/google?state=${nonce}`
  }

  // Submit function
  const submit = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {
      reset()

      // Show error message
      set_error_message("Invalid email address")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // Validate password
    if (password.length === 0) {
      reset()

      // Show error message
      set_error_message("Please enter your password")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // Hash master password prepare for API call
    const password__unenc__uint8_array = string_to_uint8_array(password)
    const password__hash__uint8_array = await HASH(password__unenc__uint8_array)
    const password__hash__base64 = uint8_array_to_base64(password__hash__uint8_array)

    // Execute login
    const post_auth_login_res = await axios.post(`/api/auth/login`, {
      email_address: email_address,
      password__hash: password__hash__base64,
    })

    if (!post_auth_login_res.data.success) {
      switch (post_auth_login_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to index page
          navigate(`/`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_login_res.data.status) {
      case "AUTHENTICATED": {
        // Save JWT in idb-keyval
        await idb_set("session_token", post_auth_login_res.data.session_token)

        // Global variables
        global_update({ 
          ...global_context, 
          authenticated: true,
          user_id: post_auth_login_res.data.user_id,
          type: post_auth_login_res.data.type,
        })
  
        // ___??___ This doesn't appear to be necessary, but just having it here for safety and for code readability

        // If owner or admin, navigate to campaigns page
        if (["owner", "admin"].includes(post_auth_login_res.data.type)) {
          navigate(location.state ? location.state.from : `/dashboard`)
        }
        // If not owner or admin, navigate to account page
        else {
          navigate(location.state ? location.state.from : `/dashboard`)
        }
        

        // Always break
        break
      }
      case "PASSWORD_IS_INCORRECT":
      case "USER_DOES_NOT_EXIST": {
        // Show error message
        set_error_message("Incorrect email or password")
        set_error_message_is_displayed(true)

        // Reset 
        reset()

        // Always break
        break
      }
      case "WRONG_AUTHENTICATION_TYPE": {
        // Show error message
        set_error_message("You cannot log in via password")
        set_error_message_is_displayed(true)

        // Reset 
        reset()

        // Always break
        break
      }
      case "USER_DID_NOT_COMPLETE_SIGNUP_AND_EMAIL_NOT_VALIDATED": {
        alert("Please complete signup.")

        // Redirect to validate-email page
        navigate(`/validate-email?email_validation_token_id=${post_auth_login_res.data.email_validation_token_id}&user_id=${post_auth_login_res.data.user_id}`)

        // Always break
        break
      }
      case "USER_DID_NOT_COMPLETE_SIGNUP_AND_PASSWORD_NOT_SET": {
        alert("Please complete signup.")

        // // Redirect to set-password page
        // navigate(`/set-password?user_id=${post_auth_login_res.data.user_id}`)

        // Redirect to validate-email page
        navigate(`/validate-email?email_validation_token_id=${post_auth_login_res.data.email_validation_token_id}&user_id=${post_auth_login_res.data.user_id}`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders
  useEffect(() => {

    // Focus on email address field upon load
    email_address_ref.current.focus()

    // Mixpanel tracking
    mixpanel_client_track("app_login_visited", null)
  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
          <ResponsiveLogoMarkOnly className="h-8 w-auto" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  ref={email_address_ref}
                  value={email_address}
                  onChange={(e) => handle_user_input("email_address", e.target.value)}
                  onKeyDown={(e) => handle_enter_keydown(e, submit)}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <Link to="/reset" className="font-semibold text-blue-600 hover:text-blue-500">
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={password}
                  onChange={(e) => handle_user_input("password", e.target.value)}
                  onKeyDown={(e) => handle_enter_keydown(e, submit)}
                />
              </div>
            </div>

            {error_message_is_displayed
            ? <div className="flex space-x-2 items-start">
                <div>
                  <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                </div>
                <div className="text-sm font-medium text-red-400">{error_message}</div>
              </div>
            : <></>}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={submit}
              >
                {is_awaiting ? (
                  <ReactLoading
                    type='spokes'
                    color='#ffffff'
                    height={20}
                    width={20}
                  />
                ) : (
                  <span>Log in</span>
                )}
              </button>
            </div>
          </div>

          

          {/* SSO options */}
          <div className="mt-10 flex flex-col justify-center items-center space-y-4">
            <div className="text-sm text-gray-500">
              Or log in with
            </div>
            <div className="flex space-x-4">
              {/* Google */}
              <button
                onClick={redirect_to_google_oauth}
                className="flex items-center space-x-1 hover:scale-105"
              >
                <GoogleLogo 
                  className="h-4 w-4"
                />
                <div className="text-sm">Google</div>
              </button>

              {/* Microsoft */}
              {/* <Link
                to={`/api/auth/google`}
                className="flex items-center space-x-1"
              >
                <GoogleLogo 
                  className="h-4 w-4"
                />
                <div className="text-sm">Google</div>
              </Link> */}

                
            </div>
          </div>



          {/* Sign up link */}
          <p className="mt-10 text-center text-sm text-gray-500">
            No account?{" "}
            <Link to="/signup" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
              Sign up
            </Link>
          </p>


        </div>
      </div>
    </div>
  )
}

export default LoginPage