
import axios from 'axios';
import { useEffect, useState, useRef } from 'react'


// GlobalProvider
import { useGlobal, useGlobalUpdate } from "../../contexts/GlobalContext";

import ReactLoading from "react-loading"
import { useNavigate, useSearchParams, Link } from 'react-router-dom';

import { InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ResponsiveLogoMarkOnly } from '../../components/logo';


import { handle_enter_keydown } from '../../libs/helpers';
import { mixpanel_client_track } from '../../libs/mixpanelClient';
import OTP from '../../components/OTP'




const ValidateEmailPage = ({
  
} : {

}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Search params
  const [search_params, _] = useSearchParams()

  const email_validation_token_id = search_params.get("email_validation_token_id")
  const user_id = search_params.get("user_id")
  const first_name = search_params.get("first_name")
  const last_name = search_params.get("last_name")


  // Navigate
  const navigate = useNavigate()

  // Autofocus on code field
  const code_ref = useRef<any>(null)

  // User input
  // const [code, set_code] = useState("")
  const [otp, set_otp] = useState(Array(6).fill("")); // Array with 6 empty strings
  const otp_input_refs = useRef([]);

  // Error message
  const [error_message, set_error_message] = useState("")
  const [error_message_is_displayed, set_error_message_is_displayed] = useState(false)

  // Awaiting
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      // case "code": {
      //   set_code(value)

      //   // Always break
      //   break
      // }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
    set_error_message_is_displayed(false)
  }



  // Submit function
  const submit = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // Join code into a single string
    const code = otp.join("")

    // START OF USER INPUT CHECK

    // Validate code

    if (code.length === 0) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please enter the code.")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    if (code.length !== 6) {
      reset()

      // Show error message
      set_error_message("Incorrect code")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Submit code
    const post_auth_signup_code_res = await axios.post(`/api/auth/signup/code`, {
      email_validation_token_id: email_validation_token_id,
      user_id: user_id,
      code: code,
    })

    if (!post_auth_signup_code_res.data.success) {
      switch (post_auth_signup_code_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to signup
          navigate(`/signup`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_signup_code_res.data.status) {
      case "CODE_IS_CORRECT": {
        // Redirect to set-password page
        navigate(`/set-password?user_id=${post_auth_signup_code_res.data.user_id}${first_name ? `&first_name=${first_name}` : ""}${last_name ? `&last_name=${last_name}` : ""}`)

        // Always break
        break
      }
      case "USER_DOES_NOT_EXIST": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      ///////
      // Note: this case will likely never occur
      case "USER_EXISTS_AND_PASSWORD_NOT_SET": {
        
        alert("Email address had already been verified previously. Continuing to password setup.")

        // Redirect to set-password page
        navigate(`/set-password?user_id=${post_auth_signup_code_res.data.user_id}${first_name ? `&first_name=${first_name}` : ""}${last_name ? `&last_name=${last_name}` : ""}`)

        // Always break
        break
      }
      ///////
      case "USER_EXISTS_AND_ALREADY_COMPLETED_SIGNUP": {
        alert("This account had already completed signup previously. Redirecting to login page.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "VALIDATION_TOKEN_DOES_NOT_EXIST": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      case "CODE_IS_INCORRECT": {
        // Show error message
        set_error_message("Incorrect code")
        set_error_message_is_displayed(true)

        // Reset 
        reset()
        
        // Always break
        break
      }
      case "TOKEN_TYPE_IS_WRONG": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)
        
        // Always break
        break
      }
      case "USER_ID_DOES_NOT_MATCH": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }
  
  // Renders
  useEffect(() => {

    // // Focus on code field upon load
    // code_ref.current.focus()
    
    // Mixpanel tracking
    mixpanel_client_track("app_validate_email_visited", null)

  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
          <ResponsiveLogoMarkOnly className="h-8 w-auto" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm leading-6 text-gray-900 flex">
                <span className="font-medium">Verification code</span>
                <span className="flex relative items-center">
                  <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                  <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                    Please check your inbox. If you don't see the verification code email in your inbox, please check your promotions tab & spam folder as well.
                  </span>
                </span>
              </label>
              <div className="mt-2">
                {/* <input
                  type="tel"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  ref={code_ref}
                  value={code}
                  onChange={(e) => handle_user_input("code", e.target.value)}
                  onKeyDown={(e) => handle_enter_keydown(e, submit)}
                /> */}
                <OTP otp={otp} set_otp={set_otp} otp_input_refs={otp_input_refs} submit_fn={submit} />
              </div>
            </div>

            {error_message_is_displayed
            ? <div className="flex space-x-2 items-start">
                <div>
                  <ExclamationTriangleIcon className="pt-[3px] w-4 h-4 text-red-400 h-full"/>
                </div>
                <div className="text-sm font-medium text-red-400">{error_message}</div>
              </div>
            : <></>}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={submit}
              >
                {is_awaiting ? (
                  <ReactLoading
                    type='spokes'
                    color='#ffffff'
                    height={20}
                    width={20}
                  />
                ) : (
                  <span>Continue</span>
                )}
              </button>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ValidateEmailPage